import React from "react";

import { StyleSheet } from "react-native";

import { TextareaItem } from "@ant-design/react-native";
import { TextareaItemProps } from "@ant-design/react-native/lib/textarea-item";

type CustomizedTextAreaItemType = (props: TextareaItemProps) => React.JSX.Element;

const styles = StyleSheet.create({
  textArea: {
    backgroundColor: "#fafafa",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
});

const CustomizedTextAreaItem: CustomizedTextAreaItemType = (props: TextareaItemProps) => {
  return <TextareaItem {...props} style={styles.textArea} scrollEnabled={true} multiline={true} rows={4} />;
};

export default CustomizedTextAreaItem;
