import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Text } from "react-native";

import { t } from "i18n-js";

import { filter, find, findIndex, orderBy } from "lodash";

import { Loading, convertToArray } from "../../../components/index";
import { AuthenticatedUserContext } from "../../../providers/index";
import { WorkingGroupNameModal } from "./WorkingGroupNameModal.jsx";

import { updateSession } from "../../../services/SessionsService.js";

import { useFocusEffect } from "@react-navigation/native";

import { StudentList } from "./StudentList";

import { ActionSheet, Button, Flex, Icon, View } from "@ant-design/react-native";

import { Platform } from "react-native";
import { useTestSessionsContext, useTestSettingsContext } from "../../../providers/SettingsProvider";

const AddStudents = ({ route, navigation }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);
  const { settingsDispatch } = useTestSettingsContext();

  const {
    sessionsData: { sessions },
    sessionsDispatch,
  } = useTestSessionsContext();

  const { students, session: sessionToSearch } = route.params;

  const session = useMemo(() => find(sessions, ({ id }) => id === sessionToSearch.id));

  const [workingGroupName, setWorkingGroupName] = useState("");

  const [loading, setLoading] = useState(true);

  const [allStudents, setAllStudents] = useState([]);

  const studentsToAddRef = useRef([]);

  const [workingGroupModalOpen, setWorkingGroupModalOpen] = useState(false);

  const setStudentsToAdd = (payload) => {
    sessionsDispatch({
      type: "setStudentsToAdd",
      payload,
    });
  };

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const handleSessionUpdate = async (session, key, value) => {
    const { id, ...newSession } = session;
    newSession[key] = value;
    updateSession(id, newSession, sessions);
    navigation.goBack();
  };

  const addStudents = async (subgroupName, newStudentsToAdd) => {
    if (subgroupName !== "") {
      newStudentsToAdd = newStudentsToAdd.map((ss) => {
        return {
          ...ss,
          group: ss.group + " \\ " + subgroupName,
        };
      });
    }

    const newSessionData = { ...session };

    newStudentsToAdd.forEach((s) => {
      const index = findIndex(session.students, (ss) => ss.key === s.key);
      if (index === -1) {
        newSessionData.students.push(s);
      }
    });

    await handleSessionUpdate(session, "students", newSessionData.students);
  };

  const toggleStudentsToAdd = (student) => {
    const index = findIndex(studentsToAddRef.current, (s) => s.key === student.key);
    const newStudentsToAdd = [...studentsToAddRef.current];

    if (index === -1) {
      newStudentsToAdd.push(student);
    } else {
      newStudentsToAdd.splice(index, 1);
    }
    studentsToAddRef.current = newStudentsToAdd;
  };

  const closeModal = () => {
    setWorkingGroupName("");
    setStudentsToAdd([]);
  };

  const closeWorkingGroupModal = () => {
    setWorkingGroupName("");
    setWorkingGroupModalOpen(false);
  };

  const selectAllStudents = () => (studentsToAddRef.current = allStudents);

  const cleanStudentsToAdd = () => (studentsToAddRef.current = []);

  const handleUserAddActionSheet = () => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [t("sessions.add.subgroupName"), t("addSelected")],
        cancelButtonIndex: 4,
        destructiveButtonIndex: 3,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            setWorkingGroupModalOpen(true);
            break;

          case 1:
            addStudents(workingGroupName, studentsToAddRef.current);
            break;

          default:
            return;
        }
      }
    );
  };

  const webStyles =
    Platform.OS === "web"
      ? {
          height: "98%",
        }
      : {};

  useEffect(() => {
    const getAllStudents = () => {
      const studentsArray = convertToArray(userProfile.students).filter((s) => {
        const index = findIndex(students, (ss) => ss?.key === s?.key);
        if (index > -1) return false;
        return true;
      });

      return orderBy(
        filter(studentsArray, (student) => !student?.deleted),
        ["group", "name"]
      );
    };

    setAllStudents(getAllStudents());
    setLoading(false);
  }, [students]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setDrawerAdditionalItems([]);
      };
    }, [])
  );

  if (loading) return <Loading />;

  return (
    <View style={webStyles}>
      <WorkingGroupNameModal
        isOpen={workingGroupModalOpen}
        onChangeText={setWorkingGroupName}
        onClose={closeWorkingGroupModal}
        onFinish={() => {
          addStudents(workingGroupName, studentsToAddRef.current);
          setWorkingGroupModalOpen(false);
          closeModal();
        }}
      />

      <StudentList
        allStudents={allStudents}
        sessionStudents={session.students}
        toggleStudentsToAdd={toggleStudentsToAdd}
      />

      <Flex justify="center">
        <View style={{ width: "95%" }}>
          <Flex justify="between">
            <Button
              onPress={() => {
                closeModal();
                navigation.goBack();
              }}
              style={{ width: "49%" }}
            >
              <Text>{t("cancel")}</Text>
            </Button>

            <Button type="primary" style={{ width: "49%" }} onPress={handleUserAddActionSheet}>
              <Flex justify="center">
                <Icon name="user-add" size="xs" color="#fff" />
              </Flex>
              <Text style={{ color: "white" }}>{t("add")}</Text>
            </Button>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};
export default AddStudents;
