import { useFocusEffect } from "@react-navigation/native";
import { collection, getDoc, onSnapshot, query, where, doc, setDoc, updateDoc } from "firebase/firestore";
import { t } from "i18n-js";
import { differenceWith, filter, find, forEach, isNil, isNull, map, orderBy } from "lodash";
import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { FlatList, Platform, RefreshControl, Text } from "react-native";
import { Loading, store } from "../../../components";

import { AuthenticatedUserContext } from "../../../providers";
import { useTestSessionsContext, useTestStudentsContext } from "../../../providers/SettingsProvider";
import { updateSession } from "../../../services";
import { SessionCard } from "./SessionCard";

const sessionsRef = collection(store, "sessions");

const withSessionsAndStudents = (SessionList) => {
  const Memoized = memo(SessionList);

  return ({ navigation }) => {
    const { sessionsDispatch, sessionsData } = useTestSessionsContext();
    const { studentsDispatch } = useTestStudentsContext();

    return (
      <Memoized
        navigation={navigation}
        sessionsDispatch={sessionsDispatch}
        studentsDispatch={studentsDispatch}
        sessionsData={sessionsData}
      />
    );
  };
};

export const SessionList = withSessionsAndStudents(
  ({ sessionsDispatch, sessionsData, navigation, studentsDispatch }) => {
    const { sessions } = sessionsData;
    const { user } = useContext(AuthenticatedUserContext);

    const setSessions = (payload) => {
      sessionsDispatch({
        type: "setSessions",
        payload,
      });
    };

    const setSessionData = (payload) => {
      sessionsDispatch({
        type: "setSessionData",
        payload,
      });
    };

    const [listData, setListData] = useState(null);
    const [loading, setLoading] = useState(true);

    const setReducedStudentsListForSessionStudentsScreen = (payload) => {
      studentsDispatch({
        type: "setReducedStudentsListForSessionStudentsScreen",
        payload,
      });
    };

    const handleSessionUpdate = useCallback(
      (session, key, value) => {
        const { id, ...newSession } = session;
        newSession[key] = value;
        updateSession(id, newSession, sessions);
      },
      [sessions]
    );

    const renderItem = useCallback(
      ({ item, index }) => {
        return (
          <SessionCard
            session={item}
            index={index}
            sessions={sessions}
            setSessions={setSessions}
            handleSessionUpdate={handleSessionUpdate}
            navigation={navigation}
          />
        );
      },
      [listData, sessions]
    );

    const getSessions = () => {
      try {
        const sessionsQuery = query(sessionsRef, where("users", "array-contains", user.uid));

        return onSnapshot(sessionsQuery, (snapshot) => {
          const tempSessions = [];

          snapshot.forEach((session) => {
            const sessionData = session.data();

            if (!sessionData.isDeleted) {
              tempSessions.push({
                id: session.id,
                ...sessionData,
                students: filter(sessionData.students, (student) => !student?.deleted),
              });
            }

            // define if we have legacy value of sessions and compare
          });

          navigation.setOptions({
            title: t("sessions.title"),
          });

          setSessions(orderBy(tempSessions, "name"));
          setListData(orderBy(tempSessions, "name"));
        });
      } catch (err) {
        console.log(err);
      } finally {
        if (sessions.length === 0 && !isNil(listData)) setLoading(false);
        else
          setTimeout(() => {
            setLoading(false);
          }, 3000);
      }
    };

    useFocusEffect(
      useCallback(() => {
        setSessionData({
          name: "",
          students: [],
          enabled: false,
          hasSelfEvaluation: true,
        });

        setReducedStudentsListForSessionStudentsScreen(null);
      }, [loading])
    );

    useEffect(() => {
      const unsubscribe = getSessions();
      return unsubscribe;
    }, []);

    if (loading || isNull(listData)) return <Loading />;
    if (listData.length === 0)
      return <Text style={{ padding: "0 5px", marginLeft: 10 }}>{t("sessions.list.noSessions")}</Text>;

    return (
      <FlatList
        data={listData}
        renderItem={renderItem}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={() => sessions} />}
        refreshing={loading}
        initialNumToRender={10}
        contentContainerStyle={{ paddingBottom: 250 }}
        keyExtractor={(v) => v?.key ?? v?.id}
      />
    );
  }
);
