import React from "react";
import { Text } from "react-native";
import { Flex, Icon } from "@ant-design/react-native";
import { t } from "../../../components";

const EmptyList = () => (
  <Flex justify="center">
    <Icon name="close" />
    <Text>{t("students.noStudents")}</Text>
  </Flex>
);
export default EmptyList;
