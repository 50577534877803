import React, { memo, useCallback, useMemo } from "react";
import { FlatList } from "react-native";
import { useDimensionsContext } from "../../providers/SettingsProvider";
import { DimensionCard } from "./DimensionsCards/DimensionCard";

import { keys } from "lodash";

const withDimensionsContext = (AllBehavioursList) => {
  const Memoized = memo(AllBehavioursList);

  return (props) => {
    const { dimensionsData } = useDimensionsContext();

    return <Memoized {...props} dimensionsData={dimensionsData} />;
  };
};

export const AllBehavioursList = withDimensionsContext(
  ({ isAllCollapsed, session, dimensionsData }) => {
    const { reducedDimensions, dimensions } = dimensionsData;

    const listData = useMemo(() => {
      return keys(reducedDimensions ? reducedDimensions : dimensions).map(
        (x) => {
          return { id: x, name: x };
        }
      );
    }, [reducedDimensions, dimensions]);

    const renderItem = useCallback(
      ({ item }) => {
        return (
          <DimensionCard
            key={`${item.id}_${Math.floor(Math.random() * 1000)}`}
            category={item.name}
            session={session}
            isAllCollapsed={isAllCollapsed}
          />
        );
      },
      [isAllCollapsed, session]
    );

    return (
      <FlatList
        data={listData}
        renderItem={renderItem}
        initialNumToRender={7}
        maxToRenderPerBatch={2}
        contentContainerStyle={{
          paddingBottom: 280,
        }}
      />
    );
  }
);
