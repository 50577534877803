import React from 'react';

import { Platform, Text } from 'react-native';

import { Flex, View } from '@ant-design/react-native';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { DrawerToggleButton } from '@react-navigation/drawer';
import { StudentsScreen } from '../screens/EvaluationStack/StudentsScreen';
import { UserEvaluation } from '../screens/EvaluationStack/UserEvaluation';

import { t } from 'i18n-js';

const Stack = createNativeStackNavigator();

export default function EvaluationStack() {
  const defaultOptions =
    Platform.OS === 'web'
      ? {
          headerShown: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: 'transparent',
          },
          headerLeft: () => <></>,
          headerShadowVisible: false,
        }
      : {
          headerShown: true,
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: 'transparent',
          },
          headerRight: () => <DrawerToggleButton />,
          headerShadowVisible: false,
        };

  return (
    <Stack.Navigator initialRouteName="Students" screenOptions={defaultOptions}>
      <Stack.Screen name="Students" component={StudentsScreen} options={{ title: t('students.header') }} />
      <Stack.Screen
        name="UserEvaluation"
        component={UserEvaluation}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const { student } = routes[index]?.params;

          return {
            headerBackVisible: false,
            headerTitle: () => (
              <View>
                <Flex>
                  <Text style={{ fontWeight: 'bold' }} numberOfLines={1}>
                    {student.name}
                  </Text>
                </Flex>
                <Flex>
                  <Text numberOfLines={1}>{student.group.trim()}</Text>
                </Flex>
              </View>
            ),
          };
        }}
      />
    </Stack.Navigator>
  );
}
