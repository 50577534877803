import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { isEmpty, isNil } from "lodash";

import CryptoJS from "react-native-crypto-js";

import { auth } from "../Firebase";

const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY.replace(/\\n/g, "\n");

export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  const existingCookies = document.cookie.split(";");
  let updatedCookies = [];
  let cookieExists = false;

  existingCookies.forEach((cookie) => {
    const cookiePair = cookie.split("=");
    const cookieName = cookiePair[0].trim();
    if (cookieName === name) {
      cookieExists = true;
      updatedCookies.push(`${name}=${value};${expires};path=/`);
    } else {
      updatedCookies.push(cookie.trim());
    }
  });

  if (!cookieExists) {
    updatedCookies.push(`${name}=${value};${expires};path=/`);
  }

  document.cookie = updatedCookies.join("; ");
};

export const decrypt = (text: string, secretKey: string = SECRET_KEY) => {
  const bytes = CryptoJS.AES.decrypt(text, secretKey.replace(/\\n/g, "\n"));
  if (isEmpty(text)) return;

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const handleLoginFromPlatform = async ({ login, password }) => {
  try {
    if (isNil(login) || isNil(password)) {
      await signOut(auth).then(() => (window.location.href = "/"));
    }
    await signInWithEmailAndPassword(auth, login, password);
  } catch (err) {
    console.log(err);
  }
};
