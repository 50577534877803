import React from "react";
import { t } from "./Localize";
import { ActivityIndicator, Flex, View } from "@ant-design/react-native";

export const Loading = () => (
  <View style={{ width: "100%", height: "100%" }}>
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <ActivityIndicator text={t("loading")} />
    </Flex>
  </View>
);
