import React from "react";

import { Platform, Text } from "react-native";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SessionAddScreen } from "../screens/SessionStack/SessionAddScreen";
import { SessionAddStudents } from "../screens/SessionStack/SessionAddStudents";
import { SessionListScreen } from "../screens/SessionStack/SessionListScreen";
import { SessionStudentsScreen } from "../screens/SessionStack/SessionStudentsScreen";
import { SessionUserEvaluation } from "../screens/SessionStack/SessionUserEvaluation";

import AddStudents from "../screens/SessionStack/SessionStudentsScreenComponents/AddStudents";

import { DrawerToggleButton } from "@react-navigation/drawer";

import { View } from "@ant-design/react-native";

import { t } from "../components";

import { SessionFabAdd } from "../screens/SessionStack/SessionListScreenComponents/SessionFabAdd";

const Stack = createNativeStackNavigator();

export default function SessionStack() {
  const defaultOptions =
    Platform.OS === "web"
      ? {
          headerBackTitleVisible: false,
          headerShown: true,
          headerStyle: {
            backgroundColor: "transparent",
          },
          headerLeft: () => <></>,
          headerShadowVisible: false,
        }
      : {
          headerBackTitleVisible: false,
          headerShown: true,
          headerStyle: {
            backgroundColor: "transparent",
          },
          headerRight: () => <DrawerToggleButton />,
          headerShadowVisible: false,
        };

  return (
    <Stack.Navigator initialRouteName="SessionList" screenOptions={() => defaultOptions}>
      <Stack.Screen
        name="SessionList"
        component={SessionListScreen}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();

          if (Platform.OS === "web")
            return {
              title: t("sessions.title"),
              headerRight: () => <SessionFabAdd />,
              ...(routes?.[index]?.params ?? {}),
            };

          return {
            title: t("sessions.title"),
            ...(routes?.[index]?.params ?? {}),
          };
        }}
      />

      <Stack.Screen
        name="SessionAdd"
        component={SessionAddScreen}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();

          return {
            title: t("sessions.add.addStudents"),

            ...(routes?.[index]?.params ?? {}),
          };
        }}
      />

      <Stack.Screen
        name="SessionStudents"
        component={SessionStudentsScreen}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();

          return {
            ...(routes?.[index]?.params ?? {}),
          };
        }}
      />
      <Stack.Screen
        name="AddStudents"
        component={AddStudents}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const { title } = routes[index].params;

          return {
            title,
            headerLeft: () => false,
          };
        }}
      />
      <Stack.Screen
        name="AddStudentsToSession"
        component={SessionAddStudents}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const { title } = routes[index].params;

          return {
            title,
            headerLeft: () => false,
          };
        }}
      />
      <Stack.Screen
        name="SessionUserEvaluation"
        component={SessionUserEvaluation}
        options={({ navigation }) => {
          const { routes, index } = navigation.getState();
          const { student } = routes[index].params;

          const defaultSetup = {
            headerBackVisible: false,
            headerTitle: () => (
              <View style={{ height: "auto", width: "100%" }}>
                <View style={{ width: Platform.OS === "web" ? "100%" : "70%" }}>
                  <Text numberOfLines={1} style={{ fontWeight: "bold" }}>
                    {student.name}
                  </Text>
                </View>
                <View style={{ width: Platform.OS === "web" ? "100%" : "70%" }}>
                  <Text numberOfLines={1}>{student.group.trim().split("\\").join(" | ")}</Text>
                </View>
              </View>
            ),
            ...routes[index].params,
          };

          if (Platform.OS === "web")
            return {
              headerLeft: null,
              ...defaultSetup,
            };

          return defaultSetup;
        }}
      />
    </Stack.Navigator>
  );
}
