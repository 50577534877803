import React, { useContext, useState } from "react";
import { StyleSheet } from "react-native";

import { t } from "i18n-js";

import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { verifySessionCode } from "../../services/SessionsService";

import { Button, Flex, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import CustomizedInputItem from "../../components/Inputs/CustomizedInputItem";
import { Image, Text } from "react-native";
import { AccessCodeAuthObjectInterface, Nullable, SetAccessedSessionIdInterface } from "../..";

enum StudentLoginConstants {
  FIREBASE_FUNCTIONS_TOKEN = "c1ac2d20-19a3-401f-a3a1-f5f29ef91ba4",
}

const StudentLoginScreen = ({ navigation }): JSX.Element => {
  const [sessionToken, setSessionToken] = useState<string>("");
  const [authorizedTenant, setAuthorizedTenant] = useState<Nullable<string>>(null);
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const { setAccessedSessionId }: SetAccessedSessionIdInterface = useContext(AuthenticatedUserContext);

  const goToLogin = () => navigation.navigate("Login");

  const handleSessionTokenChange = (e: string): void => setSessionToken(e.trim());

  const onSessionLogin = async () => {
    if (sessionToken.length !== 6) return Toast.fail({ content: t("sessions.invalidSessionCode"), duration: 1 });

    try {
      setIsRequestPending(true);

      const accessCodeAuthObject: any = await verifySessionCode({
        accessCode: sessionToken.trim(),
        token: StudentLoginConstants.FIREBASE_FUNCTIONS_TOKEN,
      });

      if (!accessCodeAuthObject.data.authorized)
        return Toast.fail({ content: t("sessions.invalidSessionCode"), duration: 1 });

      const { sessionId, authorizedTenant, authenticationToken }: AccessCodeAuthObjectInterface =
        accessCodeAuthObject.data;

      const result = await signInWithCustomToken(auth, authenticationToken);

      if (result) {
        setAuthorizedTenant(authorizedTenant);
        setAccessedSessionId(sessionId);
        navigation.navigate("SessionStudentsList");
      }
    } catch (err) {
      return Toast.fail({ content: t("unauthorized"), duration: 1 });
    } finally {
      setIsRequestPending(false);
    }
  };

  return (
    <View style={styles.full}>
      <Flex justify="center" align="center" style={styles.full} direction="column">
        <Image source={require("../../assets/logoIcon.png")} style={styles.image} alt="Pentabilities" />
        <WhiteSpace size="lg" />
        <View style={styles.maxWidth}>
          <WingBlank size="md">
            <Flex style={styles.maxWidth} justify="start">
              <Text style={styles.boldText}>{t("login.token")}</Text>
            </Flex>
            <CustomizedInputItem onChangeText={handleSessionTokenChange} value={sessionToken} />
          </WingBlank>
        </View>
        <Text>{t("login.students.characterLength")}</Text>
        <WhiteSpace size="md" />
        <Button type="primary" onPress={onSessionLogin} loading={isRequestPending}>
          {t("login.header")}
        </Button>
        <WhiteSpace size="md" />
        <Flex align="center">
          <Text style={styles.underline} onPress={goToLogin}>
            {t("login.goToLogin")}
          </Text>
        </Flex>
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({
  full: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: 50,
    height: 50,
  },
  maxWidth: {
    width: "100%",
  },
  boldText: {
    fontWeight: "bold",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

export default StudentLoginScreen;
