import { Accordion } from "@ant-design/react-native";
import { useFocusEffect } from "@react-navigation/native";
import { t } from "i18n-js";
import { find, isNil } from "lodash";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Platform } from "react-native";
import { useTestSessionsContext, useTestSettingsContext } from "../../providers/SettingsProvider";
import { FocusedBehaviourList } from "./FocusedBehaviourList";

import { AllBehavioursList } from "./AllBehavioursList";
import { doc, onSnapshot } from "firebase/firestore";
import { store } from "../Firebase";

const withSettingsContext = (DimensionsCardsList) => {
  const Memoized = memo(DimensionsCardsList);

  return (props) => {
    const { settingsDispatch } = useTestSettingsContext();

    return <Memoized {...props} settingsDispatch={settingsDispatch} />;
  };
};

export const DimensionsCardsList = withSettingsContext(
  ({
    selectedBehaviours = null,
    activeSections,
    route,
    setActiveSections,
    settingsDispatch,
    isAllCollapsed,
    setIsAllCollapsed,
  }) => {
    const {
      sessionsData: { sessions },
    } = useTestSessionsContext();

    const [session, setSession] = useState(route?.params?.session);

    const showFocusedBehaviours = true;

    const showAllBehaviours = useMemo(() => {
      return session?.selectedBehaviours && session?.selectedBehaviours.length > 0 ? false : true;
    }, [session]);

    const setDrawerAdditionalItems = (payload) => {
      settingsDispatch({
        type: "setDrawerAdditionalItems",
        payload,
      });
    };

    const webStyles =
      Platform.OS === "web"
        ? {
            height: "70%",
          }
        : {
            paddingBottom: 125,
          };

    const toggleShowEvidences = () => {
      setIsAllCollapsed((prev) => {
        if (prev) setActiveSections([0]);
        else setActiveSections([1]);

        return !prev;
      });
    };

    useEffect(() => {
      setSession(find(sessions, ({ id }) => id === route?.params?.session.id) ?? route.params.session);

      const unsubscribe = onSnapshot(doc(store, `sessions/${session.key}`), (snap) => {
        if (!snap.exists()) return;
        setSession(snap.data());
      });

      return unsubscribe;
    }, []);

    useFocusEffect(
      useCallback(() => {
        if (!(showAllBehaviours && !showAllBehaviours)) {
          setDrawerAdditionalItems([
            {
              label: t("toggle.list"),
              callback: toggleShowEvidences,
              icon: "monitor",
            },
          ]);
        }
      }, [])
    );

    if (showFocusedBehaviours && session.selectedBehaviours?.length > 0)
      return (
        <Accordion
          activeSections={activeSections}
          onChange={setActiveSections}
          renderAsFlatList={true}
          style={{ backgroundColor: "white", ...webStyles }}
        >
          <Accordion.Panel header={t("sessions.evaluation.featured")} key={0}>
            <FocusedBehaviourList selectedBehaviours={session?.selectedBehaviours ?? []} session={session} />
          </Accordion.Panel>

          <Accordion.Panel header={t("sessions.evaluation.all")} key={1}>
            <AllBehavioursList isAllCollapsed={isAllCollapsed} session={session} />
          </Accordion.Panel>
        </Accordion>
      );

    return <AllBehavioursList isAllCollapsed={isAllCollapsed} session={session} />;
  }
);
