import React, { useContext, useEffect, useState } from "react";

import * as Notifications from "expo-notifications";

import { updateUserDeviceToken } from "../services/SettingsService";

import { DrawerToggleButton, createDrawerNavigator } from "@react-navigation/drawer";
import CustomDrawer from "./CustomDrawer";

import Constants from "expo-constants";

import { t } from "i18n-js";
import { Platform } from "react-native";

import { onValue, ref } from "firebase/database";
import { isNil, keys } from "lodash";
import { AuthenticatedUserContext } from "../providers";

import { db } from "./Firebase";
import { init as localizeInit } from "./Localize";

import { SettingsScreen } from "../screens/SettingsScreen";

import EvaluationStack from "./EvaluationStack";
import SessionStack from "./SessionStack";

import Changelog from "../screens/Changelog";

import { useDimensionsContext, useTestSettingsContext } from "../providers/SettingsProvider";
import GoBackIcon from "./GoBackIcon";
import { Loading } from "./Loading";

const Drawer = createDrawerNavigator();

const getDimensions = (tenantName) => {
  return new Promise((resolve, reject) => {
    onValue(ref(db, `tenants/${tenantName}/defaults/evaluation`), (snapshot) => {
      const data = snapshot.val();
      if (!data) resolve(null);
      resolve(data);
    });
  });
};

const checkIfDemoModeEnabled = (tenantName) => {
  return new Promise((resolve, reject) => {
    onValue(ref(db, `tenants/${tenantName}/defaults/settings/demo`), (snapshot) => {
      const data = snapshot.val();
      if (!data) resolve(false);
      resolve(data);
    });
  });
};

const getProfileDimensions = (uid) => {
  return new Promise((resolve, reject) => {
    onValue(ref(db, `users/${uid}/profile/dimensions`), (snapshot) => {
      const data = snapshot.val();
      if (!data) resolve(null);
      resolve(data);
    });
  });
};

const getFocusedDimensions = (uid) => {
  return new Promise((resolve, reject) => {
    onValue(ref(db, `users/${uid}/profile/focusedDimensions`), (snapshot) => {
      const data = snapshot.val();
      if (!data) resolve(null);
      resolve(data);
    });
  });
};

const views = {
  sessions: SessionStack,
  students: EvaluationStack,
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const { user, setUserProfile, userProfile, setIsEvaluator, setIsResearch, setDemoModeEnabled, setLanguage } = useContext(AuthenticatedUserContext);

  const {
    dimensionsData: { checkedWithBackend, dimensions },
    dimensionsDispatch,
  } = useDimensionsContext();

  const { settingsDispatch } = useTestSettingsContext();

  const setLoadingDimensions = (payload) => {
    dimensionsDispatch({
      type: "setLoadingDimensions",
      payload,
    });
  };

  const setCheckedWithBackend = (payload) => {
    dimensionsDispatch({
      type: "setCheckedWithBackend",
      payload,
    });
  };

  const setDimensions = (payload) => {
    dimensionsDispatch({
      type: "setDimensions",
      payload,
    });
  };
  const setShowEvidences = (payload) => {
    dimensionsDispatch({
      type: "setShowEvidences",
      payload,
    });
  };
  const setWithTranslation = (payload) => {
    dimensionsDispatch({
      type: "setWithTranslation",
      payload,
    });
  };

  const setFocusedDimensions = (payload) => {
    dimensionsDispatch({
      type: "setFocusedDimensions",
      payload,
    });
  };

  const setTags = (payload) => {
    settingsDispatch({
      type: "setTags",
      payload,
    });
  };

  const [currentView, setCurrentView] = useState(null);

  const screenOptions = {
    drawerPosition: Platform.OS === "web" ? "left" : "right",
    drawerType: "slide",
    headerStyle: {
      height: 80,
      borderBottomColor: Platform.OS === "web" ? "transparent" : "inherit",
    },
  };

  const webOptionsForSettingsScreen =
    Platform.OS === "web"
      ? {}
      : {
          headerRight: () => <DrawerToggleButton />,
        };

  useEffect(() => {
    const getUserProfile = () => {
      try {
        onValue(ref(db, `users/${user.uid}`), (snapshot) => {
          const userProfileData = snapshot.val();

          if (userProfileData) {
            setUserProfile(userProfileData);

            setTags(userProfileData.profile.tags && userProfileData.profile.tags !== "" ? userProfileData.profile.tags : []);

            if (userProfileData.profile.role !== "Student") {
              setIsEvaluator(true);
              setCurrentView("sessions");
            } else {
              setCurrentView("students");
            }

            if (userProfileData.profile.role === "Research") {
              setIsResearch(true);
            }

            if (!checkedWithBackend) {
              setLoadingDimensions(true);
              getDimensions(userProfileData.profile.tenant)
                .then((tenantDimensions) => {
                  if (tenantDimensions) {
                    setWithTranslation(true);
                    setDimensions(tenantDimensions);
                  }
                })
                .then(() => {
                  getProfileDimensions(user.uid).then((profileDimensions) => {
                    if (profileDimensions) {
                      setWithTranslation(true);
                      setDimensions(profileDimensions);
                    }
                  });
                  getFocusedDimensions(user.uid).then((focusedDimensions) => {
                    if (focusedDimensions) {
                      setFocusedDimensions(focusedDimensions);
                    }
                  });
                })
                .catch((err) => console.log("err", err));

              setLoadingDimensions(false);
              setCheckedWithBackend(true);
            }

            checkIfDemoModeEnabled(userProfileData.profile.tenant).then((isEnabled) => {
              setDemoModeEnabled(isEnabled);
              if (isEnabled) {
                const newUserProfile = { ...userProfileData };
                newUserProfile.students = {
                  "0sk1OwkeIgRUavNX9NNayj8lwjc3": {
                    group: "Demo Group",
                    name: "Demo User 1",
                    timestamp: 1664531806775,
                  },
                };
                setUserProfile(newUserProfile);
              }
            });

            const showEvidencesMap = {};
            keys(dimensions).forEach((d) => (showEvidencesMap[d] = true));
            setShowEvidences(showEvidencesMap);

            setLanguage(userProfileData.profile.language);
            localizeInit(userProfileData.profile.language);
          }
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = getUserProfile();

    if (Platform.OS !== "web") {
      Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig.extra.eas.projectId,
      }).then((token) => {
        updateUserDeviceToken(token.data);
        console.log(token.data);
      });
    }

    return unsubscribe;
  }, []);

  if ((isNil(userProfile) && loading) || isNil(currentView)) return <Loading />;

  return (
    <Drawer.Navigator initialRouteName={currentView} drawerContent={(props) => <CustomDrawer {...props} />} screenOptions={screenOptions}>
      {!isNil(currentView) && (
        <Drawer.Screen
          name={currentView}
          component={views[currentView]}
          options={{
            drawerItemStyle: { opacity: 0 },
            headerShown: false,
          }}
        />
      )}

      <Drawer.Screen
        name={"settings"}
        component={SettingsScreen}
        options={(props) => ({
          drawerItemStyle: { height: 0 },
          headerShown: true,
          headerTitle: `${t("settings.hi")} ${userProfile.profile.name ?? ""}`,
          headerLeft: () => <GoBackIcon callback={() => props.navigation.goBack()} styles={{ style: { marginLeft: 20 } }} />,
          ...webOptionsForSettingsScreen,
        })}
      />

      <Drawer.Screen
        name={"changelog"}
        component={Changelog}
        options={{
          headerShown: false,
          drawerItemStyle: { height: 0 },
        }}
      />
    </Drawer.Navigator>
  );
};

export default App;
