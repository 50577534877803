import React, { memo, useContext, useEffect, useState } from "react";

import { Button, Flex, Icon, Modal, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import CustomizedTextAreaItem from "../components/Inputs/CustomizedTextAreaItem";

import { Text } from "react-native";

import { AuthenticatedUserContext } from "../providers";

import * as Device from "expo-device";

import { reportIssue } from "../services";

import { t } from "i18n-js";

import { FeedbackModal } from "../components/Feedback/Modal/FeedbackModal";

interface AuthContextImportProps {
  [keyof: string]: any;
}

interface IssueReportModalProps {
  navigation?: any;
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error?: Error;
  additionalCallback?(): null;
  fromErrorView?: boolean;
  errorMessage?: string;
}

type statusType = "success" | "pending" | "error";

const IssueReportModal = memo(
  ({
    navigation,
    isDrawerOpen,
    setIsDrawerOpen,
    error = null,
    additionalCallback = null,
    fromErrorView = false,
    errorMessage = "Error when adding comment",
  }: IssueReportModalProps) => {
    const [textAreaValue, setTextAreaValue] = useState<string>("");
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState<boolean>(false);
    const [feedbackStatus, setFeedbackStatus] = useState<statusType>("pending");

    const { userProfile, language }: AuthContextImportProps = useContext(AuthenticatedUserContext);

    const handleIssueSend = async () => {
      try {
        setIsRequestPending(true);
        setIsFeedbackModalVisible(true);

        let title = null;
        if (textAreaValue) {
          const splitText = textAreaValue.split(" ");
          if (splitText.length > 2) title = `${splitText[0]}  ${splitText[1]} ${splitText[2]}`;
          else title = `${splitText[0]}`;
        }
        const { osName, osVersion, platformApiLevel, brand } = Device;

        const payload = {
          deviceType: `${osName}`,
          platform: `${osName}: ${osVersion}, API level: ${platformApiLevel} , MODEL: ${brand}`,
          language: language,
          fullDescription: textAreaValue,
          shortDescription:
            error?.message && error?.name && error?.stack
              ? `Error name : ${error.name} \n Error message : ${error.message} \n Error stack : ${error.stack}`
              : "",
          description: textAreaValue,
          tenant: userProfile.profile.tenant,
          email: userProfile.profile?.email ?? "",
          issueType: "not specified",
          title: title,
        };

        const result = await reportIssue(payload);

        if (result) setFeedbackStatus("success");
        else setFeedbackStatus("error");

        setTextAreaValue("");
        handleCloseModal();
        setIsRequestPending(false);
      } catch (err) {
        console.log(err);
      } finally {
        additionalCallback !== null ?? additionalCallback();
      }
    };

    const handleCloseModal = () => {
      setIsDrawerOpen(false);
      setTextAreaValue("");
    };

    useEffect(() => {
      setFeedbackStatus("pending");
    }, [isFeedbackModalVisible]);

    return (
      <>
        <FeedbackModal
          fromErrorView={fromErrorView || feedbackStatus === "error"}
          visible={isFeedbackModalVisible}
          setModalVisible={setIsFeedbackModalVisible}
          pendingMessage={t("issueReport.submittingIssue")}
          successMessage={t("issueReport.issueSubmitted")}
          errorMessage={feedbackStatus ? t("issueReport.error") : errorMessage}
          status={feedbackStatus}
        />
        <Modal
          title={t("issueReport.title")}
          transparent={true}
          maskClosable={true}
          visible={isDrawerOpen}
          onClose={handleCloseModal}
          animationType={"slide-up"}
        >
          <View>
            <WhiteSpace size="md" />
            <CustomizedTextAreaItem defaultValue={textAreaValue} onChangeText={setTextAreaValue} />

            <WhiteSpace size="md" />
            <Button onPress={handleIssueSend} loading={isRequestPending} type="warning">
              <Flex>
                <WingBlank size="sm">
                  <Text style={{ color: "white" }}>{t("issueReport.submit")}</Text>
                </WingBlank>
                <WingBlank size="sm">
                  <Icon name="save" />
                </WingBlank>
              </Flex>
            </Button>
          </View>
        </Modal>
      </>
    );
  }
);

export default IssueReportModal;
