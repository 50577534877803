import * as Localization from "expo-localization";
import I18n from "i18n-js";
import memoize from "lodash.memoize";

export const getTranslation = {
  en: () => require("../assets/languages/en.json"),
  es: () => require("../assets/languages/es.json"),
  ca: () => require("../assets/languages/ca.json"),
  pt: () => require("../assets/languages/pt.json"),
};

const languages = ["en", "es", "ca", "pt"];

export const t = memoize(
  (key, config) =>
    I18n.t(key, config).includes("missing") ? { key } : I18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

export const init = (code = "en") => {
  // clear memoize locale on init
  t.cache.clear();
  I18n.translations = {
    en: require("../assets/languages/en.json"),
    es: require("../assets/languages/es.json"),
    ca: require("../assets/languages/ca.json"),
    pt: require("../assets/languages/pt.json"),
  };

  let localeLanguageTag = Localization.locale.split("-")[0];
  localeLanguageTag = code;

  if (languages.indexOf(localeLanguageTag) === -1) {
    localeLanguageTag = "en";
  }
  I18n.defaultLocale = languages[0];
  I18n.enableFallback = true;
  I18n.locale = localeLanguageTag;

  return localeLanguageTag;
};
