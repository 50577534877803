import { ref, set, update } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db, storage, functions } from "../components";
import { httpsCallable } from "firebase/functions";

import * as ImagePicker from "expo-image-picker";

const updateUserProfile = (data) => {
  return new Promise((resolve, reject) => {
    set(ref(db, `users/${auth.currentUser.uid}/profile`), data)
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

const updateUserDeviceToken = (token) => {
  return new Promise((resolve, reject) => {
    update(ref(db, `users/${auth.currentUser.uid}/profile/`), { deviceToken: token })
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

const updateTags = (tags) => {
  set(ref(db, `users/${auth.currentUser.uid}/profile/tags`), tags);
};

const pickAvatar = async () => {
  new Promise((resolve, reject) => {
    ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 4],
    }).then((pickerResult) => {
      if (!pickerResult.cancelled) {
        const blob = new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = () => resolve(xhr.response);
          xhr.onerror = (err) => {
            reject(err.message);
          };

          xhr.responseType = "blob";
          xhr.open("GET", pickerResult.uri, true);
          xhr.send(null);
        });

        const fileRef = storageRef(storage, `images/${auth.currentUser.uid}_avatar`);
        uploadBytes(fileRef, blob);

        resolve("ok");
      }
    });
  });
};

const reportIssue = async (payload) => {
  try {
    const createIssue = httpsCallable(functions, "createIssue");
    const result = await createIssue(payload);

    if (result) return true;

    throw new Error();
  } catch (err) {
    return false;
  }
};

const getAvatarUrl = (uid = auth.currentUser.uid) => {
  const fileRef = storageRef(storage, `images/${uid}_avatar`);

  return getDownloadURL(fileRef);
};

export { updateUserProfile, updateTags, pickAvatar, getAvatarUrl, reportIssue, updateUserDeviceToken };
