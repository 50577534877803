import React from "react";
import { DimensionValue, Platform, RegisteredStyle, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { InputItem } from "@ant-design/react-native";
import { InputItemProps } from "@ant-design/react-native/lib/input-item";

type CustomizedInputItemType = (props: InputItemProps) => React.JSX.Element;

const styles = StyleSheet.create({
  input: {
    backgroundColor: "#fafafa",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    width: (Platform.OS === "web" ? "90vw" : "90%") as DimensionValue,
  },
});

const CustomizedInputItem: CustomizedInputItemType = (props: InputItemProps) => {
  return <InputItem {...props} style={styles.input} />;
};

export default CustomizedInputItem;
