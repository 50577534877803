import React, { memo, useCallback, useContext, useEffect } from "react";
import { Platform } from "react-native";

import { t } from "i18n-js";

import { useTestSettingsContext } from "../../providers/SettingsProvider";

import { View } from "@ant-design/react-native";

import { useFocusEffect } from "@react-navigation/native";

import { signOut } from "firebase/auth";
import { isNil } from "lodash";
import { auth } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { SessionFabAdd } from "./SessionListScreenComponents/SessionFabAdd";
import { SessionList } from "./SessionListScreenComponents/SessionList";
import { decrypt } from "../../components/encryptionsHelpers";
import { handleIframeRedirection } from "../../helpers/handleIframeRedirection";

const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

const withSettings = (SessionListScreen) => {
  const Memoized = memo(SessionListScreen);

  return ({ navigation }) => {
    const { settingsDispatch } = useTestSettingsContext();
    return <Memoized settingsDispatch={settingsDispatch} navigation={navigation} />;
  };
};

export const SessionListScreen = withSettings(({ settingsDispatch, navigation }) => {
  const { user, setUser } = useContext(AuthenticatedUserContext);

  const navigateToAddSessionScreen = () => {
    navigation.navigate("SessionAdd", { headerLeft: () => false });
  };

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS === "web") {
        const isUidMatched = user?.uid === window.localStorage.getItem("userId");

        if (user && !isUidMatched) {
          signOut(auth).then(() => {
            setUser(null);
          });
        }
      }
      navigation.setOptions({
        title: t("sessions.title"),
      });

      settingsDispatch({
        type: "setDrawerAdditionalItems",
        payload: [],
      });
    }, [])
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      handleIframeRedirection(window, document); // NOTE: temporarly commented out

      const listener = window.addEventListener("message", ({ data }) => {
        if (!isNil(data.chg))
          signOut(auth).then(() => {
            setUser(null);
          });

        const encryptedLogin = data?.l1 ?? null;
        const encryptedPassword = data?.p1 ?? null;

        const decryptedLogin = decrypt(encryptedLogin, SECRET_KEY.trim());
        const decryptedPassword = decrypt(encryptedPassword, SECRET_KEY.trim());

        if (encryptedLogin && encryptedPassword) {
          window.localStorage.setItem("login", decryptedLogin);
          window.localStorage.setItem("password", decryptedPassword);

          if (decryptedLogin.toLowerCase() !== window.localStorage.getItem("userEmail").toLowerCase())
            signOut(auth).then(() => {
              setUser(null);
            });
        }

        if (data.responder)
          window.parent.postMessage(
            JSON.parse(
              JSON.stringify({
                received: true,
              })
            ),
            data.responder
          );

        return;
      });

      return () => listener;
    }
  }, []);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <SessionList navigation={navigation} />
      {Platform.OS !== "web" && <SessionFabAdd navigateToAddSessionScreen={navigateToAddSessionScreen} />}
    </View>
  );
});
