import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { FlatList, Platform, RefreshControl, RegisteredStyle, Text, ViewStyle } from "react-native";

import { StudentItemSelectable } from "../../components/studentShared/StudentItemSelectable";

import { filter, findIndex, includes, map, uniqBy } from "lodash";

import { useFocusEffect } from "@react-navigation/native";
import { t } from "i18n-js";
import { StudentInterface } from "../..";

import { ActionSheet, Button, Flex, Icon, View } from "@ant-design/react-native";
import { useTestSessionsContext, useTestSettingsContext } from "../../providers/SettingsProvider";
import { WorkingGroupNameModal } from "./SessionStudentsScreenComponents/WorkingGroupNameModal";

//make proper types for contexts
interface ContextProps {
  [keyof: string]: any | any[];
}

interface StudentListProps {
  allStudents: StudentInterface[];
  toggleStudentsToAdd(student: StudentInterface): void;
}

const StudentList: React.FC<StudentListProps> = memo(({ allStudents, toggleStudentsToAdd }: StudentListProps) => {
  const [studentsToDisplay, setStudentsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    sessionsData: { sessionData, studentsToAdd },
  } = useTestSessionsContext();

  const renderItem = useCallback(
    ({ item, index }) => {
      const studentItemProps = {
        key: item.key,
        item: item,
        onPress: () => toggleStudentsToAdd(item),
        avatarColor: false,
      };

      return <StudentItemSelectable {...studentItemProps} />;
    },
    [allStudents, toggleStudentsToAdd]
  );

  useEffect(() => {
    if (loading) {
      const students = filter(allStudents, ({ key }) => {
        const index = findIndex(studentsToAdd, (student: any) => student.key === key);
        if (index > -1) return false;
        return true;
      }).filter((v) => {
        return !includes(
          map(sessionData.students, ({ key }) => key),
          v.key
        );
      });

      setStudentsToDisplay(students);
      setLoading(false);
    }
  }, [loading, allStudents, sessionData]);

  return (
    <FlatList
      refreshing={loading}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={() => studentsToDisplay} />}
      data={studentsToDisplay}
      keyExtractor={(item: any) => item.key}
      renderItem={renderItem}
      initialNumToRender={14}
      style={{ paddingLeft: 2, paddingRight: 2, height: "93%" }}
      numColumns={1}
    />
  );
});

export const SessionAddStudents = ({ route, navigation }): JSX.Element => {
  const { allStudents } = route.params;

  const [subgroupName, setSubgroupName] = useState<string>("");
  const [workingGroupModalOpen, setWorkingGroupModalOpen] = useState<boolean>(false);

  const {
    sessionsData: { sessionData, studentsToAdd },
    sessionsDispatch,
  } = useTestSessionsContext();

  const { settingsDispatch } = useTestSettingsContext();

  const setSessionData = (payload) => {
    sessionsDispatch({
      type: "setSessionData",
      payload,
    });
  };

  const setStudentsToAdd = (payload) => {
    sessionsDispatch({
      type: "setStudentsToAdd",
      payload,
    });
  };

  //to type
  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const studentsToAddRef: React.MutableRefObject<StudentInterface[]> = useRef([]);

  const setStudentsToAddLocal = useCallback(
    (students: StudentInterface[]) => {
      studentsToAddRef.current = students;
    },
    [studentsToAddRef]
  );

  const selectAllStudents = useCallback((): void => {
    setStudentsToAddLocal(allStudents);
    navigation.closeDrawer();
  }, [allStudents]);

  const cleanStudentsToAdd = useCallback((): void => {
    setStudentsToAddLocal([]);
    navigation.closeDrawer();
  }, []);

  const cancelAddStudents = useCallback((): void => {
    navigation.goBack();
    setSubgroupName("");
    setStudentsToAddLocal([]);
  }, []);

  const updateSessionData = useCallback(
    (key: string, value: StudentInterface[]): void => {
      const newSessionData = { ...sessionData };
      newSessionData[key] = key === "students" ? uniqBy([...newSessionData[key], ...value], "key") : value;
      setSessionData(newSessionData);
    },
    [sessionData, setSessionData]
  );

  const updateStudents = (): void => {
    if (subgroupName !== "") {
      studentsToAddRef.current = map(studentsToAddRef.current, (student) => ({
        ...student,
        group: student.group + " \\ " + subgroupName,
      }));
    }

    updateSessionData("students", [...sessionData.students, ...studentsToAdd, ...studentsToAddRef.current]);
    setStudentsToAdd([...studentsToAdd, ...studentsToAddRef.current]);

    navigation.goBack();
  };

  const toggleStudentsToAdd = useCallback(
    (student: StudentInterface): void => {
      const index = findIndex(studentsToAddRef.current, (s: any) => s.key === student.key);
      const newStudentsToAdd = [...studentsToAddRef.current];
      if (index === -1) {
        newStudentsToAdd.push(student);
      } else {
        newStudentsToAdd.splice(index, 1);
      }
      setStudentsToAddLocal(newStudentsToAdd);
    },
    [studentsToAddRef]
  );

  const openSubgroupModal = (): void => setWorkingGroupModalOpen(true);
  const closeWorkingGroupModal = (): void => setWorkingGroupModalOpen(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setDrawerAdditionalItems([]);
        setStudentsToAdd([]);
      };
    }, [])
  );

  const workingGroupModalProps = {
    isOpen: workingGroupModalOpen,
    onChangeText: setSubgroupName,
    textValue: subgroupName,
    onClose: closeWorkingGroupModal,
    onFinish: () => {
      setWorkingGroupModalOpen(false);
      updateStudents();
    },
  };

  const handleUserAddActionSheet = () => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [t("sessions.add.subgroupName"), t("addSelected")],
        cancelButtonIndex: 4,
        destructiveButtonIndex: 3,
      },
      (buttonIndex: number) => {
        switch (buttonIndex) {
          case 0:
            openSubgroupModal();
            break;

          case 1:
            updateStudents();
            break;

          default:
            return;
        }
      }
    );
  };

  const webStyles: RegisteredStyle<ViewStyle> | {} =
    Platform.OS === "web"
      ? {
          height: "98%",
        }
      : {};

  return (
    <View style={webStyles}>
      <StudentList allStudents={allStudents} toggleStudentsToAdd={toggleStudentsToAdd} />

      <WorkingGroupNameModal {...workingGroupModalProps} />

      <Flex justify="center">
        <View style={{ width: "95%" }}>
          <Flex justify="between">
            <Button onPress={cancelAddStudents} style={{ width: "49%" }}>
              <Text>{t("cancel")}</Text>
            </Button>

            <Button type="primary" style={{ width: "49%" }} onPress={handleUserAddActionSheet}>
              <Flex justify="center">
                <Icon name="user-add" size="xs" color="#fff" />
              </Flex>
              <Text style={{ color: "white" }}>{t("add")}</Text>
            </Button>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};
