import { findIndex, isUndefined, uniq, isNil } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useTestSettingsContext, useVotesContext } from "../../../providers/SettingsProvider";
import { EvaluationStarRating } from "../../../screens/EvaluationStack/UserEvaluationComponents/EvaluationStarRating";

export const MemoizedEvaluationStartRating = ({ category, item, session }) => {
  const {
    settings: { tags },
  } = useTestSettingsContext();
  const { allVotes, setAllVotes } = useVotesContext();

  const handleSave = (evidence, value) => {
    const newVotes = { ...allVotes };
    if (isUndefined(newVotes[category])) {
      newVotes[category] = {};
    }

    newVotes[category][evidence] = {
      ...(newVotes[category][evidence] || {}),
      tags,
      timestamp: Math.round(+new Date() / 1000),
      value: value?.value === 0 ? null : value?.value,
    };

    if (!isUndefined(session) && findIndex(newVotes[category][evidence].tags, (t) => t.includes("session")) === -1) {
      newVotes[category][evidence].tags.push(
        `session:${session.name}:${session.id ?? session.key}:${session?.statEntry ?? ""}`
      );
    }
    if (isUndefined(session.id ?? session.key) || isUndefined(session.statEntry)) throw new Error();

    if (value.value === 0 && isUndefined(newVotes[category][evidence].comment)) delete newVotes[category][evidence];
    if (value.comment === "") delete newVotes[category][evidence].comment;

    setAllVotes(newVotes);
  };

  const getTemporaryEvaluationRatingIfExists = useCallback(
    (category, evidence) => {
      return allVotes?.[category]?.[evidence]?.value ?? 0;
    },
    [allVotes]
  );

  return useMemo(() => {
    return (
      <EvaluationStarRating
        defaultRating={() => getTemporaryEvaluationRatingIfExists(category, item)}
        onStarRatingChange={(value) => handleSave(item, { value })}
      />
    );
  }, [allVotes]);
};
