import React, { useState } from "react";

import { Button, Flex, Icon, View, WhiteSpace, WingBlank } from "@ant-design/react-native";

import { StyleSheet, Text } from "react-native";

import { t } from "../../components";

import IssueReportModal from "../IssueReportModal";

enum ErorrBoundaryConstants {
  icon = "close-circle",
  iconColor = "red",
}

const ErrorBoundaryScreen = ({ error, resetError }) => {
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const handleGoBack = () => resetError();
  const handleOpenReportIssueModal = () => setIsReportModalOpen(true);
  console.log(error);

  return (
    <View style={styles.full}>
      <IssueReportModal isDrawerOpen={isReportModalOpen} setIsDrawerOpen={setIsReportModalOpen} error={error} additionalCallback={handleGoBack} fromErrorView={true} />
      <Flex style={styles.full} justify="center" align="center" direction="column">
        <Icon name={ErorrBoundaryConstants.icon} color={ErorrBoundaryConstants.iconColor} size={50} />
        <WhiteSpace size="lg" />
        <Text style={styles.bold}>{t("updatesHandling.generalError")}</Text>
        <WhiteSpace size="lg" />
        <WingBlank size="md">
          <Flex>
            <Button onPress={handleGoBack}>
              <Text>{t("updatesHandling.back")}</Text>
            </Button>
            <WingBlank size="md" />
            <Button type="warning" onPress={handleOpenReportIssueModal}>
              <Flex>
                <Text style={styles.white}>{t("updatesHandling.report")}</Text>
                <WingBlank size="sm" />
                <Icon name="phone" />
              </Flex>
            </Button>
          </Flex>
        </WingBlank>
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({
  full: {
    width: "100%",
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  white: {
    color: "white",
  },
});

export default ErrorBoundaryScreen;
