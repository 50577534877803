import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useContext, useEffect } from "react";

import { signOut } from "firebase/auth";
import { filter, find, groupBy, includes, isEmpty, isNil, keys, orderBy } from "lodash";
import { Loading, SearchBar, auth, convertToArray, t } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { updateTags } from "../../services";
import { StudentsByGroup } from "./StudentsScreenComponents/StudentsByGroup";
import { StudentsList } from "./StudentsScreenComponents/StudentsList";
import { Tag } from "./StudentsScreenComponents/Tag";

import { View, WhiteSpace } from "@ant-design/react-native";
import { useTestSettingsContext, useTestStudentsContext } from "../../providers/SettingsProvider";

import { Platform } from "react-native";
import { decrypt } from "../../components/encryptionsHelpers";
import { handleIframeRedirection } from "../../helpers/handleIframeRedirection";

const SECRET_KEY = process.env.EXPO_PUBLIC_ENCRYPTION_KEY;

export const StudentsScreen = ({ navigation }) => {
  const { navigate } = navigation;
  const { userProfile, isEvaluator, language, user, setUser } = useContext(AuthenticatedUserContext);
  const {
    studentsData: { showTags, showStudentsGroups, reducedStudentsListForStudentsScreen },
    studentsDispatch,
  } = useTestStudentsContext();

  const {
    settings: { tags },
    settingsDispatch,
  } = useTestSettingsContext();

  const setTagsView = (payload) => {
    studentsDispatch({
      type: "setTagView",
      payload,
    });
  };

  const setReducedStudentsListForStudentsScreen = (payload) => {
    studentsDispatch({
      type: "setReducedStudentsListForStudentsScreen",
      payload,
    });
  };

  const setTags = (payload) => {
    settingsDispatch({
      type: "setTags",
      payload,
    });
  };

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const toggleGroupView = () => {
    studentsDispatch({
      type: "toggleGroupView",
    });
  };

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS === "web") {
        const isUidMatched = user?.uid === window.localStorage.getItem("userId");

        if (user && !isUidMatched) {
          signOut(auth).then(() => {
            setUser(null);
          });
        }
      }

      setDrawerAdditionalItems([
        {
          label: t("toggle.list"),
          callback: toggleGroupView,
          icon: "ordered-list",
        },
      ]);
      navigation.setOptions({
        title: t("students.header"),
      });
      if (tags.length > 0 && !showTags) {
        setTagsView(true);
      }
    }, [])
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      handleIframeRedirection(window, document); // NOTE: temporarly commented out

      const listener = window.addEventListener("message", ({ data }) => {
        if (!isNil(data.chg))
          signOut(auth).then(() => {
            setUser(null);
          });

        const encryptedLogin = data?.l1 ?? null;
        const encryptedPassword = data?.p1 ?? null;

        const decryptedLogin = decrypt(encryptedLogin, SECRET_KEY.trim());
        const decryptedPassword = decrypt(encryptedPassword, SECRET_KEY.trim());

        if (encryptedLogin && encryptedPassword) {
          window.localStorage.setItem("login", decryptedLogin);
          window.localStorage.setItem("password", decryptedPassword);

          if (decryptedLogin.toLowerCase() !== window.localStorage.getItem("userEmail").toLowerCase())
            signOut(auth).then(() => {
              setUser(null);
            });
        }

        if (data.responder)
          window.parent.postMessage(
            JSON.parse(
              JSON.stringify({
                received: true,
              })
            ),
            data.responder
          );

        return;
      });

      return () => listener;
    }
  }, []);

  const searchStudents = (text) => {
    const newStudentsList = convertToArray(userProfile.students).filter((student) => {
      if (student?.deleted) return false;

      let studentSearchGroup = false;
      if (typeof student.group == "string") {
        studentSearchGroup = student.group.toUpperCase().trim().includes(text.toUpperCase().trim());
      }
      return student.name.toUpperCase().trim().includes(text.toUpperCase().trim()) || studentSearchGroup;
    });
    setReducedStudentsListForStudentsScreen(newStudentsList);
  };

  const resetStudents = () => {
    setReducedStudentsListForStudentsScreen(null);
  };

  const getTags = () => {
    if (!userProfile.profile.tags || isEmpty(userProfile.profile.tags)) return [];
    return userProfile.profile.tags;
  };

  const handleTagsChange = (tags) => {
    setTags(tags);
    updateTags(tags);
  };
  const getGroupList = useCallback(() => {
    const byGroup = groupBy(allStudents, "group");
    const groups = keys(byGroup).map((x) => {
      return { id: x, name: x };
    });
    return groups;
  }, [allStudents]);

  const getAllStudents = useCallback(() => {
    const studentsArray = convertToArray(userProfile.students);
    const currentStudent = find(userProfile.students, (student, key) => includes(key, user.uid));
    let userSubgroup = null;

    if (currentStudent) {
      const splittedGroup = currentStudent?.group.split("\\");
      const subgroup = splittedGroup[splittedGroup.length - 1];

      if (subgroup) userSubgroup = subgroup;
    }

    return orderBy(
      filter(studentsArray, (student) => !student?.deleted),
      ["group", "name"]
    );
  }, [userProfile.students]);

  const allStudents = getAllStudents();
  const groupList = getGroupList();

  if (!language) return <Loading />;

  return (
    <View>
      <WhiteSpace size="sm" />
      <SearchBar onChangeText={searchStudents} resetFunction={resetStudents} />
      {showTags && isEvaluator && <Tag onChangeTags={(tags) => handleTagsChange(tags)} initialTags={getTags()} />}

      {!showStudentsGroups && (
        <StudentsList
          reducedStudentsListForStudentsScreen={
            reducedStudentsListForStudentsScreen ? reducedStudentsListForStudentsScreen : allStudents
          }
          allStudents={allStudents}
          groupList={groupList}
          navigate={navigate}
        />
      )}
      {showStudentsGroups && (
        <StudentsByGroup
          data={reducedStudentsListForStudentsScreen ? reducedStudentsListForStudentsScreen : allStudents}
          navigate={navigate}
        />
      )}
    </View>
  );
};
