import React from "react";
import { t } from "../../../components";
import { Flex, Icon } from "@ant-design/react-native";
import { Text } from "@ant-design/react-native";

export const EmptyList = () => (
  <Flex justify="center">
    <Icon name="close" />
    <Text>{t("students.noStudents")}</Text>
  </Flex>
);
