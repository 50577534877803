import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { find, findIndex, groupBy, isUndefined, keys, orderBy } from "lodash";

import { Loading, SearchBar, convertToArray, store, t } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { updateSession, updateTags } from "../../services";
import { Tag } from "../EvaluationStack/StudentsScreenComponents/Tag";

import { useFocusEffect } from "@react-navigation/native";
import StudentsByGroup from "./SessionStudentsScreenComponents/StudentsByGroup.jsx";

import { Flex, View, WhiteSpace } from "@ant-design/react-native";
import { Platform } from "react-native";
import { StudentsList } from "../../components/studentShared/StudentsList";
import {
  useTestSessionsContext,
  useTestSettingsContext,
  useTestStudentsContext,
} from "../../providers/SettingsProvider";
import { WebMenu } from "./SessionStudentsScreenComponents/WebMenu";

export const SessionStudentsScreen = ({ navigation, route }) => {
  const [sessionStats, setSessionStats] = useState({ overall: { all: 0 } });

  const {
    studentsData: { showTags, showStudentsGroups, reducedStudentsListForSessionStudentsScreen },
    studentsDispatch,
  } = useTestStudentsContext();

  const {
    sessionsData: { sessions },
  } = useTestSessionsContext();

  const { language } = useContext(AuthenticatedUserContext);

  const {
    settings: { tags },
    settingsDispatch,
  } = useTestSettingsContext();

  const { session: sessionToSearch } = route.params;

  const session = useMemo(() => find(sessions, ({ id }) => id === sessionToSearch.id), [sessions]);

  const { navigate } = navigation;

  const setReducedStudentsListForSessionStudentsScreen = (payload) => {
    studentsDispatch({
      type: "setReducedStudentsListForSessionStudentsScreen",
      payload,
    });
  };

  const setTags = (payload) => {
    settingsDispatch({
      type: "setTags",
      payload,
    });
  };

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const deleteStudent = async (student) => {
    const newSessionData = { ...session };
    const index = findIndex(newSessionData?.students ?? [], (s) => student.key === s.key);

    if (index === -1) throw new Error("Can`t find the student.");

    newSessionData?.students.splice(index, 1);

    await handleSessionUpdate(session, "students", newSessionData?.students);
  };

  const searchStudents = (text) => {
    const newStudentsList = convertToArray(session?.students).filter((student) => {
      return (
        stipAccents(student.name.toUpperCase()).indexOf(text.trim().toUpperCase()) > -1 ||
        student.group.toUpperCase().indexOf(text.trim().toUpperCase()) > -1
      );
    });
    setReducedStudentsListForSessionStudentsScreen(newStudentsList);
  };

  const handleTagsChange = (tags) => {
    setTags(tags);
    updateTags(tags);
  };

  const getGroupList = () => {
    const byGroup = groupBy(allStudents(), "group");
    const groups = keys(byGroup).map((x) => {
      return { id: x, name: x };
    });
    return groups;
  };

  const allStudents = () => {
    const studentsArray = session.students;
    return orderBy(studentsArray, ["group", "name"]);
  };

  const handleSessionUpdate = async (session, key, value) => {
    const { id, ...newSession } = session;
    newSession[key] = value;
    await updateSession(id, newSession, sessions, {});
  };

  const currentStudentsList = useMemo(() => {
    const sessionIdentifier = !isUndefined(session.key) ? session.key : session.id ?? "";

    return find(sessions, ({ id, key }) => id === sessionIdentifier || key === sessionIdentifier)?.students;
  }, [sessions, sessionStats]);

  const groupList = useMemo(() => getGroupList(), []);

  const resetStudents = () => {
    setReducedStudentsListForSessionStudentsScreen(null);
  };

  const toggleTagView = () =>
    studentsDispatch({
      type: "toggleTagView",
    });
  const toggleGroupView = () =>
    studentsDispatch({
      type: "toggleGroupView",
    });

  const stipAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  useFocusEffect(
    useCallback(() => {
      setDrawerAdditionalItems([
        {
          label: t("toggle.tag"),
          callback: toggleTagView,
          icon: "tag",
        },
        {
          label: t("sessions.add.addStudents"),
          callback: () => {
            navigate("AddStudents", {
              title: t("sessions.add.addStudents"),
              students: currentStudentsList,
              session,
            });
          },
          icon: "user-add",
        },
        {
          label: t("toggle.list"),
          callback: toggleGroupView,
          icon: "ordered-list",
        },
      ]);
    }, [])
  );

  useEffect(() => {
    if (!session) return;

    const enableSessionStats = () => {
      if (!session.statEntry || !session.enabled) return;
      const unsubscribe = onSnapshot(doc(store, `sessionStats/${session.id}/entries/${session.statEntry}`), (snap) => {
        if (!snap.exists()) return;
        setSessionStats(snap.data());
      });

      return unsubscribe;
    };

    const unsubscribe = (() => enableSessionStats())();

    return unsubscribe;
  }, [sessions, session]);

  if (!language) return <Loading />;

  return (
    <View>
      <WhiteSpace size="sm" />
      {Platform.OS === "web" ? (
        <>
          <Flex wrap="wrap" justify="between">
            <SearchBar onChangeText={searchStudents} resetFunction={resetStudents} />
            <WebMenu
              currentStudentsList={currentStudentsList}
              session={find(sessions, ({ id }) => id === session.id)}
            />
          </Flex>
          <WhiteSpace />
        </>
      ) : (
        <SearchBar onChangeText={searchStudents} resetFunction={resetStudents} />
      )}

      {showTags && <Tag onChangeTags={(tags) => handleTagsChange(tags)} initialTags={tags ?? []} />}

      {!showStudentsGroups && (
        <StudentsList
          currentStudentsList={
            reducedStudentsListForSessionStudentsScreen
              ? reducedStudentsListForSessionStudentsScreen
              : currentStudentsList
          }
          deleteStudent={deleteStudent}
          sessionStats={sessionStats}
          session={find(sessions, ({ id }) => id === session.id)}
          groupList={groupList}
          navigate={navigate}
        />
      )}
      {showStudentsGroups && <StudentsByGroup navigate={navigate} session={session} sessionStats={sessionStats} />}
    </View>
  );
};
