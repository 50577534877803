import React, { useCallback, useContext, useRef, useState } from "react";
import { FlatList, StyleSheet } from "react-native";

import { useFocusEffect } from "@react-navigation/native";
import { filter, findIndex, orderBy, uniqBy } from "lodash";
import { Platform } from "react-native";
import { Loading, convertToArray, t } from "../../components";
import { AuthenticatedUserContext } from "../../providers";
import { createSession } from "../../services";
import { StudentItem } from "./AddSessionScreenComponents/StudentItemNoAvatar";

import { Button, Flex, Icon, Text, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import CustomizedInputItem from "../../components/Inputs/CustomizedInputItem";
import { useTestSessionsContext, useTestSettingsContext } from "../../providers/SettingsProvider";

export const SessionAddScreen = ({ navigation, route }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);

  const {
    sessionsData: { sessions, sessionData },
    sessionsDispatch,
  } = useTestSessionsContext();

  const { settingsDispatch } = useTestSettingsContext();

  const setSessions = (payload) => {
    sessionsDispatch({
      type: "setSessions",
      payload,
    });
  };

  const setSessionData = (payload) => {
    sessionsDispatch({
      type: "setSessionData",
      payload,
    });
  };

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const [savingSession, setSavingSession] = useState(false);

  const sessionNameRef = useRef(sessionData.name);

  if (!userProfile) return <Loading />;

  const deleteStudent = useCallback(
    (student) => {
      const newSessionData = { ...sessionData };
      const index = findIndex(newSessionData.students, (s) => student.key === s.key);
      newSessionData.students.splice(index, 1);
      setSessionData(newSessionData);
    },
    [sessionData]
  );

  const addSession = useCallback(() => {
    setSavingSession(true);
    createSession(sessionData, sessions)
      .then((newSessions) => {
        setSessions(newSessions);
        navigation.navigate("SessionList");

        Toast.info({
          content: t("saveSuccess"),
          duration: 1,
        });
        setSavingSession(false);
        setSessionData({
          name: "",
          students: [],
          enabled: false,
          hasSelfEvaluation: true,
        });
      })
      .catch((err) =>
        Toast.info({
          content: (
            <Flex direction="column">
              {t("saveError")} {err}
            </Flex>
          ),
          duration: 1,
        })
      );
  }, [sessionData, sessions]);

  const renderItem = useCallback(
    ({ item }) => {
      return <StudentItem item={item} onDelete={() => deleteStudent(item)} noChevron />;
    },
    [sessionData]
  );

  const handleGoToAddStudents = () => {
    updateSessionData("name", sessionNameRef.current);
    navigation.navigate("AddStudentsToSession", {
      title: t("sessions.add.addStudents"),
      allStudents: orderBy(
        filter(convertToArray(userProfile.students), (student) => !student?.deleted),
        ["group", "name"]
      ),
    });
  };

  const updateSessionData = (key, value) => {
    const newSessionData = { ...sessionData };
    newSessionData[key] = key === "students" ? uniqBy([...newSessionData[key], ...value], "key") : value;
    setSessionData(newSessionData);
  };

  const styles = StyleSheet.create({
    cancelButton: {
      width: "45%",
    },
    saveButton: {
      width: "45%",
    },
  });

  useFocusEffect(
    useCallback(() => {
      setDrawerAdditionalItems([
        {
          label: t("sessions.add.addStudents"),
          callback: handleGoToAddStudents,
          icon: "user-add",
        },
      ]);
      return () => {
        setDrawerAdditionalItems([]);
      };
    }, [sessionData])
  );

  return (
    <>
      <View>
        <WingBlank size="md">
          <Flex justify="start" align="center" direction="column">
            <CustomizedInputItem
              placeholder={t("sessions.add.name")}
              defaultValue={sessionData.name}
              onChange={(value) => (sessionNameRef.current = value)}
              onEndEditing={() => {
                if (Platform.OS === "android") updateSessionData("name", sessionNameRef.current);
              }}
              onBlur={() => {
                if (Platform.OS !== "android") updateSessionData("name", sessionNameRef.current);
              }}
              extra={Platform.OS === "web" && <Icon style={{ marginLeft: 5 }} color="#E9A23A" name="user-add" onPress={handleGoToAddStudents} size={24} />}
            />

            <WhiteSpace size="md" />

            <Flex justify={Platform.OS === "web" ? "center" : "between"} style={{ width: "100%" }}>
              {sessionData.students.length === 0 ? <Text>{t("sessions.add.noStudents")}</Text> : <Text>{t("students.header")}</Text>}
              {Platform.OS !== "web" && <Icon color="#E9A23A" name="user-add" onPress={handleGoToAddStudents} size={24} />}
            </Flex>

            <WhiteSpace size="md" />

            <FlatList contentContainerStyle={{ paddingBottom: 430 }} data={sessionData.students} initialNumToRender={0} renderItem={renderItem} style={{ width: "100%" }} />
          </Flex>
        </WingBlank>
      </View>
      <View style={{ width: "100%", position: "absolute", bottom: 5 }}>
        <Flex justify="center">
          <Button onPress={navigation.goBack} style={styles.cancelButton}>
            {t("cancel")}
          </Button>
          <WingBlank size="md" />
          <Button
            style={styles.saveButton}
            type="primary"
            onPress={addSession}
            loading={savingSession}
            disabled={sessionData.name === "" || sessionData.students.length === 0 || savingSession}
          >
            <Flex justify="center">
              <Icon name="save" size="sm" color="#fff" />
              <Text style={{ color: "#fff" }}>{t("sessions.add.submit")}</Text>
            </Flex>
          </Button>
        </Flex>
      </View>
    </>
  );
};
