import { NavigationContainer } from '@react-navigation/native';
import { onAuthStateChanged } from 'firebase/auth';
import { isNil } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { Result, View, Flex, Text, Tag, WingBlank } from '@ant-design/react-native';

import { Platform } from 'react-native';

import { AuthenticatedUserContext, SettingsProvider } from '../providers';
import AuthStack from './AuthStack';
import DrawerNavigation from './DrawerNavigation';
import { auth } from './Firebase';
import { Loading } from './Loading';
import { IntegrationProvider } from '../providers/SettingsProvider';
import { t } from './index';

export default function RootNavigator() {
  const { user, setUser, userProfile, colorMode, setColorMode } = useContext(AuthenticatedUserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeInterval = () => {
      setInterval(() => {
        if (Platform.OS === 'web') {
          if (localStorage) {
            localStorage.removeItem('firebase:previous_websocket_failure');
          }
        }
      }, 5000);
    };

    const unsubscribeAuth = onAuthStateChanged(auth, (authUser) => {
      try {
        if (authUser) {
          setUser(authUser);
          if (Platform.OS === 'web') {
            window.localStorage.setItem('userEmail', authUser?.email ?? 'none');
            window.localStorage.setItem('userId', authUser?.uid ?? 'none');
          }
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    });

    return () => {
      clearInterval(unsubscribeInterval);
      return unsubscribeAuth;
    };
  }, [userProfile]);

  if (isLoading) {
    return <Loading />;
  }

  const browserAgentSafari = () => {
    if (Platform.OS !== 'web') return false;
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  };

  return (
    <NavigationContainer
      theme={{
        colors: { background: '#ffffff' },
      }}
    >
      <View style={{ width: '100%', height: '100%' }}>
        {browserAgentSafari() && <Result title="" message="Safari is not supported, please use different browser!" />}
        <IntegrationProvider>
          {user && !isNil(user.email) ? (
            <SettingsProvider>
              <DrawerNavigation />
            </SettingsProvider>
          ) : (
            <AuthStack />
          )}
        </IntegrationProvider>
        {Platform.OS === 'web' && (
          <Flex justify="end">
            <Tag>
              <Text>{t('settings.buildNumber')}: </Text>
              <Text style={{ fontWeight: 'bold' }}>5.1.9</Text>
            </Tag>
            <WingBlank size="sm" />
          </Flex>
        )}
      </View>
    </NavigationContainer>
  );
}
