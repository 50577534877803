import React, { memo, useContext } from "react";
import { Platform, Pressable, Text } from "react-native";

import { t } from "..";
import { AuthenticatedUserContext } from "../../providers";

import UserAvatar from "react-native-user-avatar";

import { Checkbox, Flex, List, Tag, View, WingBlank } from "@ant-design/react-native";
import { includes } from "lodash";

const Tags = memo(({ received, given }) => {
  return (
    <Flex justify="end" align="end" direction="column">
      <Tag>
        {t("sessions.list.given")} : {given}
      </Tag>
      <WingBlank size="sm" />
      <Tag>
        {t("sessions.list.received")} : {received}
      </Tag>
    </Flex>
  );
});

export const StudentItem = memo(({ item, avatarColor, onSelect, onPress, sessionStats, session, isPressDisabled }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);

  return (
    <List.Item
      wrap={true}
      thumb={
        <UserAvatar
          name={
            typeof item.name === "string" &&
            item.name
              .split(" ")
              .map((n) => n[0])
              .join("")
              .toUpperCase()
          }
          bg={avatarColor || "orange"}
        />
      }
      extra={
        userProfile.profile.showSessionStats &&
        session.enabled && (
          <Tags
            received={sessionStats?.students?.[item.key]?.received || 0}
            given={sessionStats?.students?.[item.key]?.given || 0}
          />
        )
      }
    >
      <Pressable onPress={() => !isPressDisabled && onPress(item)} disabled={isPressDisabled}>
        {onSelect && (
          <Checkbox onPress={() => onSelect(item)}>
            <View>
              <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                {item.name}
              </Text>
              <Text numberOfLines={1}>
                {includes(item.group.trim(), "\\") ? item.group.split("\\").join(" | ") : item.group.trim()}
              </Text>
            </View>
          </Checkbox>
        )}

        {!onSelect && (
          <Flex justify={"between"} align="start" direction="column">
            <View style={{ width: "auto" }}>
              <Flex justify="start">
                <WingBlank size="md" />
                <Flex
                  direction="column"
                  justify="center"
                  align="start"
                  style={{ width: Platform.OS === "web" ? "60vw" : "90%" }}
                >
                  <Flex>
                    <Text
                      style={{ fontWeight: "bold", width: Platform.OS === "web" ? "100%" : "90%" }}
                      numberOfLines={1}
                    >
                      {item.name.trim()}
                    </Text>
                    <WingBlank size="md" />
                  </Flex>
                  <Text numberOfLines={1} style={{ width: Platform.OS === "web" ? "100%" : "90%" }}>
                    {includes(item.group.trim(), "\\") ? item.group.split("\\").join(" | ") : item.group.trim()}
                  </Text>
                </Flex>
              </Flex>
            </View>
          </Flex>
        )}
      </Pressable>
    </List.Item>
  );
});
