import React from "react";
import { Button, Flex, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { Text, StyleSheet } from "react-native";
import { t } from "i18n-js";
import { EmptyList } from "./EmptyList";

export const EmptyView = ({ navigation }: any): JSX.Element => {
  return (
    <View style={styles.full}>
      <WingBlank size="md" style={styles.fullHeight}>
        <Flex justify="around" align="center" style={styles.fullHeight} direction="column">
          <WhiteSpace size="lg" />
          <Text style={styles.bold}>{t("login.students.sessionNotAccessed")}</Text>
          <EmptyList />
          <Button type="ghost" style={styles.fullWidth} onPress={navigation.goBack}>
            <Text>{t("back")}</Text>
          </Button>
          <WhiteSpace size="lg" />
        </Flex>
      </WingBlank>
    </View>
  );
};

const styles = StyleSheet.create({
  full: {
    width: "100%",
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
});
