import React, { useCallback, memo } from "react";
import { View, Flex, WingBlank, List, WhiteSpace } from "@ant-design/react-native";
import { Text, FlatList } from "react-native";
import { StudentGroupItem } from "../../screens/SessionStack/SessionStudentsScreenComponents/StudentGroupItem";
import { isNil } from "lodash";

export const StudentGroupCard = memo(({ groupName, students, bgColor, navigate, sessionStats, session }) => {
  const renderItem = useCallback(
    ({ item }) => {
      if (isNil(session))
        return (
          <List.Item>
            <StudentGroupItem item={item} navigate={navigate} />
            <WhiteSpace size="md" />
          </List.Item>
        );
      return (
        <List.Item>
          <StudentGroupItem item={item} navigate={navigate} sessionStats={sessionStats} session={session} />
          <WhiteSpace size="md" />
        </List.Item>
      );
    },
    [sessionStats, session]
  );

  return (
    <View>
      <WingBlank size="md">
        <Flex justify="center" style={{ width: "100%" }}>
          <View
            style={{
              backgroundColor: bgColor ?? "grey",
              width: "100%",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              paddingVertical: 10,
            }}
          >
            <Flex style={{ width: "100%" }} justify="center">
              <Text style={{ fontWeight: "bold", color: "white" }}>{groupName}</Text>
            </Flex>
          </View>
        </Flex>
        <FlatList data={students} renderItem={renderItem} contentContainerStyle={{ paddingBottom: 280 }} />
      </WingBlank>
    </View>
  );
});
