import { Flex, View } from "@ant-design/react-native";
import React from "react";
import { Text } from "react-native";
import SlideIn from "../../components/animations/SlideIn";

interface AccordionHeaderProps {
  title: string;
  index: number;
  activeSections: number[];
}

export const AccordionHeader = ({ title, index, activeSections }: AccordionHeaderProps): JSX.Element => {
  return (
    <SlideIn>
      <Flex justify="center">
        <View
          style={{
            width: "100%",
            height: "auto",
            paddingVertical: 10,
          }}
        >
          <Flex style={{ width: "100%" }}>
            <Text style={{ fontWeight: "bold", color: "#213F67" }}>
              {index + 1}. {title}
            </Text>
          </Flex>
        </View>
      </Flex>
    </SlideIn>
  );
};
