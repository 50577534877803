import React, { createContext, useState } from "react";
import { convertToArray } from "../components";

export const AuthenticatedUserContext = createContext({});

export const AuthenticatedUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isEvaluator, setIsEvaluator] = useState(false);
  const [isResearch, setIsResearch] = useState(false);
  const [demoModeEnabled, setDemoModeEnabled] = useState(false);
  const [language, setLanguage] = useState(null);
  const [colorMode, setColorMode] = useState("light");
  const [accessedSessionId, setAccessedSessionId] = useState(null);

  return (
    <AuthenticatedUserContext.Provider
      value={{
        user,
        setUser,
        userProfile,
        setUserProfile,
        isEvaluator,
        setIsEvaluator,
        isResearch,
        setIsResearch,
        demoModeEnabled,
        setDemoModeEnabled,
        language,
        setLanguage,
        colorMode,
        setColorMode,
        accessedSessionId,
        setAccessedSessionId,
      }}
    >
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
