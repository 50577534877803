import React, { useEffect, useState } from "react";
import { Button, Flex, Icon, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { StyleSheet, Text } from "react-native";

import { t } from "../../";

import IssueReportModal from "../../../screens/IssueReportModal";

enum ErorrBoundaryConstants {
  icon = "close-circle",
  iconColor = "red",
}

export const ErrorView = ({ fromErrorView, message, onClose }) => {
  const [isReportIssueModalVisible, setIsReportModalVisible] = useState<boolean>(false);

  const handleOpenReportIssueModal = () => setIsReportModalVisible(true);

  useEffect(() => {
    const closeTimeout = fromErrorView ? setTimeout(() => onClose(), 2000) : null;

    return () => {
      if (closeTimeout) clearTimeout(closeTimeout);
    };
  }, []);

  return (
    <View>
      <Flex justify="center" align="center" direction="column">
        <Icon name={ErorrBoundaryConstants.icon} color={ErorrBoundaryConstants.iconColor} size={50} />
        <WhiteSpace size="lg" />
        <Text style={styles.bold}>{message}</Text>
        <WhiteSpace size="lg" />

        <IssueReportModal
          isDrawerOpen={isReportIssueModalVisible}
          setIsDrawerOpen={setIsReportModalVisible}
          errorMessage="Error when updating"
        />

        {!fromErrorView && (
          <WingBlank size="md">
            <Flex>
              <Button onPress={onClose}>
                <Text>{t("updatesHandling.back")}</Text>
              </Button>
              <WingBlank size="md" />
              <Button type="warning" onPress={handleOpenReportIssueModal}>
                <Flex>
                  <Text style={styles.white}>{t("updatesHandling.report")}</Text>
                  <WingBlank size="sm" />
                  <Icon name="phone" />
                </Flex>
              </Button>
            </Flex>
          </WingBlank>
        )}
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontWeight: "bold",
  },
  white: {
    color: "white",
  },
});
