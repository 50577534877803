import { View } from "@ant-design/react-native";
import { filter, findIndex } from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { FlatList } from "react-native";
import { StudentItemSelectable } from "../../../components/studentShared/StudentItemSelectable.jsx";

export const StudentList = memo(({ allStudents, toggleStudentsToAdd, sessionStudents }) => {
  const studentsToDisplay = useMemo(
    () =>
      filter(allStudents, ({ key }) => {
        const index = findIndex(sessionStudents, (student) => student.key === key);

        if (index > -1) return false;
        return true;
      }),
    [allStudents]
  );

  const renderItem = useCallback(({ item, index }) => {
    return (
      <StudentItemSelectable
        key={item.key}
        item={item}
        onPress={() => {
          toggleStudentsToAdd(item);
        }}
        avatarColor={false}
      />
    );
  }, []);

  return (
    <View style={{ height: "93%" }}>
      <FlatList
        data={studentsToDisplay}
        keyExtractor={(item) => item.key}
        initialNumToRender={14}
        getItemLayout={(data, index) => ({ length: 30, offset: 50 * index, index })}
        renderItem={renderItem}
      />
    </View>
  );
});
