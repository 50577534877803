import React, { useCallback, useEffect, useState, useMemo } from "react";

import { FlatList, RefreshControl } from "react-native";

import { groupBy, keys, includes, map, isEmpty } from "lodash";
import { getColor } from "../../../components";

import SlideIn from "../../../components/animations/SlideIn";
import { StudentGroupCard } from "../../../components/studentShared/StudentGroupCard";
import { t } from "../../../components";

export const StudentsByGroup = ({ data, navigate }) => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);

  const byGroup = groupBy(data, "group");

  const haveSubgorups = useMemo(() => {
    return includes(
      map(data, (student) => {
        if (includes(student.group, "\\")) return true;
        return false;
      }),
      true
    );
  }, [data]);

  const noSubgroupIndicator = t("noSubgroup");

  const currentStudents = useMemo(() => {
    return groupBy(
      map(data, (student) => {
        if (includes(student.group, "\\")) {
          return { ...student, group: student.group.split("\\").filter((v) => !isEmpty(v))[1] };
        }

        if (haveSubgorups)
          return {
            ...student,
            group: noSubgroupIndicator,
          };

        return student;
      }),
      (d) => d.group
    );
  }, [data, loading]);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <StudentGroupCard
          groupName={item.name}
          students={currentStudents[item.name]}
          bgColor={item.name === noSubgroupIndicator ? "grey" : getColor(item.name, listData)}
          navigate={navigate}
        />
      );
    },
    [listData]
  );

  useEffect(() => {
    const groups = keys(currentStudents).map((x) => {
      return { id: x, name: x };
    });

    setListData(groups);

    if (loading) {
      setLoading(false);
    }
  }, [data, loading]);

  return (
    <FlatList
      data={listData}
      refreshing={true}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={() => listData} />}
      renderItem={renderItem}
      contentContainerStyle={{ paddingBottom: 280 }}
      numColumns={1}
      maxToRenderPerBatch={3}
      initialNumToRender={10}
    />
  );
};
