import { Flex, Tag, WingBlank } from "@ant-design/react-native";
import React, { memo, useContext } from "react";
import { Pressable, Text } from "react-native";
import { t } from "../../../components";
import { AuthenticatedUserContext } from "../../../providers";

import { useRoute } from "@react-navigation/native";

export const StudentGroupItem = memo(({ item, navigate, sessionStats, session }) => {
  const { userProfile } = useContext(AuthenticatedUserContext);
  const route = useRoute();

  return (
    <Pressable
      onPress={() =>
        navigate(route?.name === "SessionStudents" ? "SessionUserEvaluation" : "UserEvaluation", {
          student: item,
          session,
        })
      }
    >
      <Flex justify={"between"} style={{ paddingVertical: 10 }}>
        <Text style={{ fontWeight: "bold" }}>{item.name}</Text>
      </Flex>

      {userProfile.profile.showSessionStats && session?.enabled && (
        <Flex>
          <Tag>
            {t("sessions.list.received")} : {sessionStats?.students?.[item.key]?.received || 0}
          </Tag>
          <WingBlank size="sm" />
          <Tag>
            {t("sessions.list.given")} : {sessionStats?.students?.[item.key]?.given || 0}
          </Tag>
        </Flex>
      )}
    </Pressable>
  );
});
