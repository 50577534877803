import React, { memo, useContext } from "react";
import { Pressable } from "react-native";
import { Flex, Icon, Text, View, WhiteSpace, Checkbox, WingBlank, List } from "@ant-design/react-native";

import { t } from "i18n-js";

import { AuthenticatedUserContext } from "../../../providers";

export const StudentItem = memo(({ item, selectable, onPress, sessionStats, noChevron, isChecked }) => {
  const { isEvaluator, userProfile } = useContext(AuthenticatedUserContext);

  return (
    <Pressable onPress={() => (selectable ? console.log("pressed") : onPress(item))}>
      <List.Item>
        <Flex justify="start">
          <WingBlank size="lg">
            {selectable && (
              <Checkbox onChange={() => onPress(item)} checked={isChecked}>
                <View>
                  <Text style={{ fontWeight: "bold" }}>{item.name}</Text>
                  <Text>{item.group}</Text>
                </View>
              </Checkbox>
            )}

            {!selectable && (
              <>
                <View style={{ width: "100%" }}>
                  <Flex>
                    <View>
                      <Text style={{ fontWeight: "bold" }}>{item.name}</Text>
                      <Text>{item.group.trim().split("\\").join(" | ")}</Text>
                    </View>

                    <WhiteSpace size="sm" />
                  </Flex>
                </View>
                {isEvaluator && sessionStats && userProfile.profile.showSessionStats && (
                  <View>
                    <Flex>
                      <Text>
                        {t("sessions.list.received")} : {sessionStats.students[item.key].received || 0}
                      </Text>

                      <WhiteSpace size="sm" />
                      <Text>
                        {t("sessions.list.given")} : {sessionStats.students[item.key].given || 0}
                      </Text>
                    </Flex>
                  </View>
                )}
              </>
            )}
          </WingBlank>
        </Flex>
        <WhiteSpace size="md" />
      </List.Item>
    </Pressable>
  );
});
