import { useEffect, useState } from "react";

import * as Location from "expo-location";
import { LocationHookReturnValues, Nullable } from "..";

export const useLocation = (): LocationHookReturnValues => {
  const [location, setLocation] = useState<Nullable<Location.LocationObject>>(null);
  const [address, setAddress] = useState<Nullable<Location.LocationGeocodedAddress[]>>(null);
  const [errorMsg, setErrorMsg] = useState<Nullable<string>>(null);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();

      if (status !== "granted") return setErrorMsg("Permission to access location was denied");

      const location = await Location.getCurrentPositionAsync({});
      const address = await Location.reverseGeocodeAsync(location.coords);

      setLocation(location);
      setAddress(address);
    })();
  }, []);
  return { location, errorMsg, address };
};
