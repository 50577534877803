import React, { memo } from "react";

import { Modal } from "@ant-design/react-native";
import { SuccessView } from "./SuccessView";
import { ErrorView } from "./ErrorView";
import { PendingView } from "./PendingView";

interface FeedbackModalProps {
  visible: boolean;
  setModalVisible(arg: boolean): void;
  pendingMessage: string;
  successMessage: string;
  errorMessage: string;
  status: "pending" | "success" | "error";
  fromErrorView?: boolean;
}

interface enumStatusStack<T> {
  pending: T;
  success: T;
  error: T;
}

export const FeedbackModal = memo(
  ({
    visible,
    setModalVisible,
    pendingMessage,
    successMessage,
    errorMessage,
    status,
    fromErrorView = false,
  }: FeedbackModalProps) => {
    const handleClose = () => setModalVisible(false);

    const currentTitle: enumStatusStack<string> = {
      pending: pendingMessage,
      success: successMessage,
      error: errorMessage,
    };

    const currentView: enumStatusStack<JSX.Element> = {
      pending: <PendingView />,
      success: <SuccessView message={successMessage} onClose={handleClose} status={status} />,
      error: <ErrorView fromErrorView={fromErrorView} message={errorMessage} onClose={handleClose} />,
    };

    return (
      <Modal
        transparent={true}
        maskClosable={true}
        visible={visible}
        onClose={handleClose}
        animationType="slide"
        title={status === "pending" ? currentTitle[status] : ""}
      >
        {currentView[status]}
      </Modal>
    );
  }
);
