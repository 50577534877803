import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { LoginScreen } from "../screens/LoginScreen";
import { ResetPasswordScreen } from "../screens/ResetPasswordScreen";

import StudentLoginScreen from "../screens/StudentsLogin/StudentLoginScreen";

import SessionStudentsAuthenticationList from "../screens/StudentsLogin/SessionStudentsAuthenticationList";

import UpdateVersionScreen from "../screens/UpdateVersionScreen";

const Stack = createNativeStackNavigator();

export default function AuthStack() {
  return (
    <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="UpdateVersion" component={UpdateVersionScreen} />
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
      <Stack.Screen name="StudentLogin" component={StudentLoginScreen} />
      <Stack.Screen name="SessionStudentsList" component={SessionStudentsAuthenticationList} />
    </Stack.Navigator>
  );
}
