import React from "react";
import { Text, StyleSheet } from "react-native";
import { ActivityIndicator, Flex, View, WhiteSpace, WingBlank } from "@ant-design/react-native";

export const PendingView = () => {
  return (
    <View>
      <Flex direction="column" align="center" justify="center">
        <WingBlank size="md">
          <WhiteSpace size="md" />
          <ActivityIndicator size="large" />
        </WingBlank>
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({});
