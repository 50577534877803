import React, { useContext, useEffect, useState } from "react";

import { DimensionValue, Platform, ScrollView, StyleSheet, Text, View } from "react-native";

import { Accordion, Button, Card, Flex, WhiteSpace, WingBlank } from "@ant-design/react-native";

import { map } from "lodash";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { t } from "../components";

import { ca, en, es } from "./WhatsNew/resources";

import { AuthenticatedUserContext } from "../providers";

import { LanguageButtonGroup } from "../components/LanguageButtonGroup";
import { AccordionContent } from "./WhatsNew/AccordionContent";
import { AccordionHeader } from "./WhatsNew/AccordionHeader";

interface ContextProps {
  [keyof: string]: any;
}
enum ChangelogConstants {
  switchName = "changelogWasViewed",
  textColor = "#274A6B",
}

const styles = StyleSheet.create({
  header: {
    fontWeight: "bold",
    color: ChangelogConstants.textColor,
    fontSize: 22,
  },
  scrollView: {
    maxHeight: "90%",
    maxWidth: "90%",
  },
  container: {
    height: "100%",
    maxWidth: "95%",
    margin: "2.5%",
  },
  maxWidth: {
    width: "100%",
  },
  justify: {
    textAlign: "justify",
  },
  mainCard:
    Platform.OS === "web"
      ? {
          maxHeight: "90%",
          overflow: "hidden",
          width: "70vw" as DimensionValue,
        }
      : {
          maxHeight: "95%",
          overflow: "hidden",
        },
  accordionScrollView: { maxHeight: Platform.OS === "ios" ? "75%" : "80%" },
});

const Changelog = ({ navigation }): JSX.Element => {
  const [activeSections, setActiveSections] = useState<number[]>([]);

  const { userProfile, language }: ContextProps = useContext(AuthenticatedUserContext);

  const resources = {
    en,
    ca,
    es,
  };

  const handleCloseModal = () => (Platform.OS === "web" ? navigation.goBack() : navigation.navigate("settings"));

  useEffect(() => {
    const switchWasViewedValue = async (value: string): Promise<void> => {
      try {
        await AsyncStorage.setItem(ChangelogConstants.switchName, value);
      } catch (err) {
        console.warn(err);
      }
    };

    //remove comment before deployment
    switchWasViewedValue("true");
  }, [language]);

  return (
    <Flex justify="center" align="center" style={styles.container}>
      <WingBlank size="lg">
        <Card style={styles.mainCard}>
          <Card.Header title={<LanguageButtonGroup />} />
          <Card.Body>
            <WingBlank size="md">
              <View>
                <WhiteSpace size="sm" />
                <WingBlank size="md">
                  <Flex justify="center">
                    <Text style={styles.header}>{t("whatsNew.title")}</Text>
                  </Flex>
                  <WhiteSpace size="md" />
                  <Text>
                    {t("settings.hi")} {userProfile.profile.name ?? ""}
                  </Text>
                  <WhiteSpace size="sm" />
                  <Text style={styles.justify}>{t("whatsNew.description")}</Text>
                </WingBlank>
              </View>

              <WhiteSpace size="sm" />

              {language === "pt" ? (
                <Text style={{ marginLeft: 9, fontWeight: "bold" }}>Sem novas atualizações.</Text>
              ) : (
                <ScrollView style={styles.accordionScrollView}>
                  <Accordion activeSections={activeSections} onChange={setActiveSections}>
                    {map(resources[language ?? "en"], ({ title, description }, index) => {
                      return (
                        <Accordion.Panel
                          key={`accordion_panel_${index}`}
                          header={
                            <AccordionHeader title={title} activeSections={activeSections} index={Number(index)} />
                          }
                        >
                          <AccordionContent
                            content={description}
                            activeSections={activeSections}
                            sectionId={Number(index)}
                          />
                        </Accordion.Panel>
                      );
                    })}
                  </Accordion>
                  <WhiteSpace size="md" />
                  <WhiteSpace size={activeSections.length > 0 ? "lg" : "sm"} />
                  <Card.Footer
                    content={
                      <Button type="primary" style={styles.maxWidth} onPress={handleCloseModal}>
                        {t("understand")}
                      </Button>
                    }
                  />
                </ScrollView>
              )}

              {language === "pt" && (
                <Card.Footer
                  content={
                    <Button type="primary" style={styles.maxWidth} onPress={handleCloseModal}>
                      {t("understand")}
                    </Button>
                  }
                />
              )}
            </WingBlank>
          </Card.Body>
        </Card>
      </WingBlank>
    </Flex>
  );
};

export default Changelog;
