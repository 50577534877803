import { Flex, Icon, WingBlank } from "@ant-design/react-native";
import { t } from "i18n-js";
import React from "react";
import { Text } from "react-native";

export const EmptyList = (): JSX.Element => {
  return (
    <Flex justify="center" align="center" style={{ width: "100%", height: "100%" }}>
      <Icon name="close" />
      <WingBlank size="sm" />
      <Text>{t("login.students.noStudentsFound")}</Text>
    </Flex>
  );
};
