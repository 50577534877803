import { useIsFocused, useFocusEffect } from "@react-navigation/native";

import React, { useCallback } from "react";

import Animated, { useAnimatedStyle, Easing, useSharedValue, withTiming } from "react-native-reanimated";

const SlideIn = ({ children, duration = 1000, reverse = false }) => {
  const isFocused = useIsFocused();

  const randomTransform = useSharedValue(reverse ? 20 : -20);
  const randomOpacity = useSharedValue(0);

  const config = {
    duration,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const style = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: withTiming(randomTransform.value, config) }],
      opacity: withTiming(randomOpacity.value, config),
    };
  });

  useFocusEffect(
    useCallback(() => {
      randomTransform.value = 0;
      randomOpacity.value = 1;
      return () => {
        randomTransform.value = reverse ? 20 : -20;
        randomOpacity.value = 0;
      };
    }, [])
  );

  if (!isFocused) return <></>;

  return <Animated.View style={[{ transform: [{ translateY: 0 }], opacity: 0 }, style]}>{children}</Animated.View>;
};

export default SlideIn;
