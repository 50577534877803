import { find, groupBy, includes, keys, map } from "lodash";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FlatList, RefreshControl } from "react-native";
import SlideIn from "../../../components/animations/SlideIn.tsx";
import { Loading, getColor } from "../../../components/index.js";
import { StudentGroupCard } from "../../../components/studentShared/StudentGroupCard.jsx";
import { useTestSessionsContext, useTestStudentsContext } from "../../../providers/SettingsProvider.js";

import { t } from "../../../components";

import { AuthenticatedUserContext } from "../../../providers/AuthenticatedUserProvider.js";

const StudentsByGroup = memo(({ navigate, sessionStats, session }) => {
  const {
    studentsData: { reducedStudentsListForSessionStudentsScreen },
  } = useTestStudentsContext();

  const {
    sessionsData: { sessions },
  } = useTestSessionsContext();

  const { language } = useContext(AuthenticatedUserContext);

  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  const haveSubgorups = useMemo(() => {
    return includes(
      map(
        reducedStudentsListForSessionStudentsScreen !== null ? reducedStudentsListForSessionStudentsScreen : find(sessions, ({ id }) => id === session.id)?.students,
        (student) => {
          if (includes(student.group, "\\")) return true;
          return false;
        }
      ),
      true
    );
  }, [reducedStudentsListForSessionStudentsScreen, sessions]);

  const noSubgroupIndicator = useMemo(() => t("noSubgroup"), [language]);

  const currentStudents = useMemo(() => {
    return groupBy(
      map(
        reducedStudentsListForSessionStudentsScreen !== null ? reducedStudentsListForSessionStudentsScreen : find(sessions, ({ id }) => id === session.id)?.students,
        (student) => {
          if (includes(student.group, "\\")) {
            return { ...student, group: student.group.split("\\")[1] };
          }

          if (haveSubgorups)
            return {
              ...student,
              group: noSubgroupIndicator,
            };

          return student;
        }
      ),
      (d) => d.group
    );
  }, [sessions, reducedStudentsListForSessionStudentsScreen, language]);

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <StudentGroupCard
          groupName={item.name}
          students={currentStudents[item.name]}
          bgColor={item.name === noSubgroupIndicator ? "grey" : getColor(item.name, groups)}
          navigate={navigate}
          sessionStats={sessionStats}
          session={session}
        />
      );
    },
    [groups, sessionStats, language]
  );

  useEffect(() => {
    const groups = keys(currentStudents).map((x) => {
      return { id: x, name: x };
    });

    setGroups(groups);

    if (loading) {
      setLoading(false);
    }
  }, [loading, currentStudents, reducedStudentsListForSessionStudentsScreen, language]);

  if (!groups) return <Loading />;

  return (
    <FlatList
      data={groups}
      renderItem={renderItem}
      refreshing={loading}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={() => groups} />}
      initialNumToRender={9}
      maxToRenderPerBatch={3}
      keyExtractor={(item) => {
        return "group_item_" + item.id + Math.floor(Math.random()) * 1000;
      }}
      contentContainerStyle={{ paddingBottom: 280 }}
    />
  );
});

export default StudentsByGroup;
