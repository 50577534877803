export const getValueBasedColor = (value) => {
  let valueNormalized = Math.round(value);
  let color;

  switch (valueNormalized) {
    case 5:
      color = "#26a65b";
      break;
    case 4:
      color = "#91e479";
      break;
    case 3:
      color = "#f7ca18";
      break;
    case 2:
      color = "#ffa400";
      break;
    case 1:
      color = "#ff5a64";
      break;
    default:
      color = "";
      break;
  }

  return color;
};
