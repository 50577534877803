import React from "react";
import Tags from "react-native-tags";
import { t } from "../../../components";

export const Tag = ({ initialTags, onChangeTags }) => (
  <Tags
    createTagOnReturn={true}
    textInputProps={{
      placeholder: t("students.tagsPlaceholder"),
    }}
    style={{ width: "95%" }}
    initialTags={initialTags}
    onChangeTags={onChangeTags}
    inputStyle={{
      borderRadius: 10,
    }}
    containerStyle={{
      marginBottom: 15,
      marginLeft: 5,
      marginRight: 5,
    }}
    tagContainerStyle={{
      borderRadius: 10,
    }}
    tagTextStyle={{
      textShadowColor: "white",
      textShadowRadius: 1,
    }}
  />
);
