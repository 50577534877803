//import analytics from "@react-native-firebase/analytics";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import logoIcon from "../assets/logoIcon.png";
import { auth, t } from "../components";

import { Button, Flex, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";

import { Image, Text } from "react-native";

import CustomizedInputItem from "../components/Inputs/CustomizedInputItem";

export const ResetPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");

  const onResetPassword = async () => {
    try {
      if (email.length === 0) {
        return;
      }
      await sendPasswordResetEmail(auth, email).then(() => {
        Toast.success({ duration: 3, content: t("login.resetOK") });
      });
    } catch (err) {
      //analytics().logEvent("password reset error", { error: err.message });

      Toast.fail({ duration: 3, content: "Error when reseting password" });
    }
  };

  const goToLogin = () => {
    navigation.navigate("Login");
  };

  return (
    <View>
      <View style={{ width: "100%" }}>
        <Flex justify="center" align="center" direction="column" style={{ width: "100%", height: "100%" }}>
          <Flex align="center">
            <Image source={logoIcon} style={{ width: 50, height: 50 }} alt="Pentabilities" />
          </Flex>

          <View style={{ width: "100%" }}>
            <WingBlank size="md">
              <Text style={{ fontWeight: "bold" }}>{t("login.email")}</Text>
              <CustomizedInputItem
                style={{ backgroundColor: "#e6e6e6", borderRadius: 5 }}
                onChangeText={(e) => setEmail(e.trim())}
              />
            </WingBlank>
          </View>
          <WhiteSpace size="lg" />
          <Button onPress={onResetPassword} type="primary">
            <Text style={{ color: "#fff" }}>{t("login.resetPassword")}</Text>
          </Button>
          <WhiteSpace size="md" />
          <View>
            <Flex align="center">
              <Text style={{ textDecorationLine: "underline" }} onPress={goToLogin}>
                {t("login.goToLogin")}
              </Text>
            </Flex>
          </View>
        </Flex>
      </View>
    </View>
  );
};
