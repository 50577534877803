import { findIndex } from "lodash";

const colorNames = ["#047857", "#7C3AED", "#FFC930", "#14B8A6", "#FB7185"];

export const getColor = (name, namesList) => {
  const position = findIndex(namesList, (x) => x.name === name) % colorNames.length;
  const color = colorNames[position];
  if (!color) return "#d1d1d1";
  return colorNames[position];
};

const categoryColors = ["#047857", "#7C3AED", "#FFC930", "#14B8A6", "#FB7185"];

export const getCategoryColor = (name, nameList) => {
  const position = findIndex(nameList, (x) => x === name) % categoryColors.length;
  const color = categoryColors[position];

  if (!color) return "#153f6a";
  return color;
};
