import { auth } from '../components';

import { includes, isNil, isUndefined, map } from 'lodash';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../components';

const saveEvidence = (student, destination, data, tenantName, session) => {
  return new Promise((resolve, reject) => {
    if (isUndefined(tenantName) || isNil(session)) {
      reject('no Tenant Name');
    }

    const newEvidenceFirestore = {
      eid: auth.currentUser.uid,
      group: student.group || '',
      uid: student.key.replace('sub_', '').replace('self_', ''),
      category: destination.category,
      behaviour: destination.evidence,
      type: student.key.includes('self') ? 'self' : student.key.includes('sub') ? 'co' : 'teacher',
      ...data,
    };
    if (
      newEvidenceFirestore.tags.length === 0 ||
      includes(
        map(newEvidenceFirestore.tags, (tag) => (includes(tag, 'undefined') ? false : true)),
        false
      )
    )
      throw new Error('Value in tags is undefined or is empty');

    resolve(
      httpsCallable(
        functions,
        'addEvaluation'
      )({
        token: 'cf69bb6d-953c-49d5-b43c-c081346b32a2',
        evidence: newEvidenceFirestore,
        studentKey: student.key,
        student,
        tenant: tenantName,
        sessionId: session.id || session.key,
        statEntryId: session.statEntry,
      })
    );
  });
};

export { saveEvidence };
