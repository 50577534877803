export const en = [
  {
    title: "Version check",
    description: "From now on, whenever a new version of the app is available, you will be prompted to download it.",
  },
  {
    title: "What’s new section",
    description:
      "When new updates are released, once you open the updated app, a window will pop up on the default screen with information about the new changes. You will also be able to review these changes through the ‘Latest updates’ section in the Settings.",
  },
  {
    title: "New enhanced design and faster performance",
    description: "We improved the design and performance for smoother navigation through the app.",
  },
  {
    title: "Bottom bar and new menu",
    description:
      "We removed the bar from the bottom of the screen.\n You can now access Settings and other menu items from the drawer which opens when clicking on the menu icon in the top right cornern.",
  },
  {
    title: "Teacher evaluations only through sessions",
    description: "Sessions is now the default screen for all teachers, and you can only evaluate through sessions.",
  },
  {
    title: "Saving evaluations",
    description:
      "When performing evaluations you now have the option to either click on ‘Save’ evaluation to save them and go back to the student list, or to click on ‘Cancel’ to go back to the student list without saving the evaluation.",
  },
  {
    title: "Grouping by subgroups",
    description:
      "We improved grouping functionality in the student list - students can be grouped by the working groups (subgroups) you assign them to within the session management.\n In case you have not created any working groups within the session, the grouping functionality will group the students by the groups they are assigned to within the Student management module (for example, 8th grade).",
  },
  {
    title: "Issue with comments fixed and the comment feature improved",
    description:
      "Issues with saving comments to behaviour evaluations were fixed.The comment icon changes its color once a comment is provided, so you now have a better overview of where you have provided a comment or not.",
  },
  {
    title: "Descriptions of skills under ‘i’ icon",
    description:
      "Instead of having to turn on ‘Show descriptions’ in Settings, the descriptions of each skill are now available by default under the info icon ‘i’. After clicking on this icon the description will pop up. You can close the description by tapping anywhere out of the pop-up window.",
  },
];

export const es = [
  {
    title: "Comprobación de versión",
    description:
      "A partir de ahora, cada vez que haya una nueva versión de la aplicación disponible, te pediremos que descargues dicha versión antes de usar la aplicación.",
  },
  {
    title: "Sección de 'Novedades",
    description:
      "Cuando se lancen nuevas actualizaciones,la primera vez que accedas a la aplicación, aparecerá una ventana en la pantalla principal con detalles sobre los cambios. También podrás revisar estos cambios a través de la sección de 'Últimas actualizaciones' en la pantalla de configuración.",
  },
  {
    title: "Nuevo diseño mejorado y rendimiento más rápido",
    description: "Mejoramos el diseño y el rendimiento para una navegación más fluida a través de la aplicación.",
  },
  {
    title: "Barra inferior y nuevo menú",
    description:
      "Hemos eliminado la barra de navegación en la parte inferior de la pantalla.\nAhora puedes acceder a la configuración y otros elementos del menú desde el cajón que se abre al hacer clic en el ícono del menú en la esquina superior derecha.",
  },
  {
    title: "Evaluaciones de docentes sólo a través de sesiones",
    description:
      "Las sesiones son ahora la pantalla predeterminada para todos/as los/las docentes, y solo puedes evaluar a través de sesiones. Si quieres evaluar a un/a estudiante, debe estar incluido/a en una sesión, para poder registrar el “contexto” en el que se genera dicha evaluación de forma correcta.",
  },
  {
    title: "Guardado de evaluaciones",
    description:
      "Al realizar evaluaciones, hemos cambiado la forma en la que se guardan las evaluaciones. Antes se guardaba a través del botón “atrás”; ahora tendrás dos botones en la parte inferior, botón 'Guardar' para guardarlas y volver a la lista de estudiantes, o botón 'Cancelar' para regresar a la lista de estudiantes sin guardar la evaluación.",
  },
  {
    title: "Agrupación por subgrupos",
    description:
      "Mejoramos la funcionalidad de agrupación en la lista de estudiantes: los estudiantes se agrupan por grupos de trabajo en caso de que estén asignados a alguno.\nSi no hay subgrupos en la sesión, la agrupación se realiza por grupos.",
  },
  {
    title: "Problema con comentarios solucionado y mejora en la función de comentarios",
    description:
      "Hemos solucionado los problemas al guardar comentarios en las evaluaciones de comportamientos. El icono de comentario cambia de color una vez que se proporciona un comentario, para que tengas una mejor visión de dónde has proporcionado un comentario y dónde no.",
  },
  {
    title: "Descripciones de habilidades bajo el icono 'i'",
    description:
      "En lugar de tener que activar 'Mostrar descripciones' en Configuración, las descripciones de cada habilidad ahora están disponibles por defecto bajo el icono de información 'i'. Después de hacer clic en este icono, aparecerá la descripción. Puedes cerrar la descripción tocando en cualquier lugar fuera de la ventana emergente.",
  },
];

export const ca = [
  {
    title: "Comprovació de versió",
    description:
      "A partir d'ara, cada vegada que hi hagi una nova versió de l'aplicació disponible, et demanarem que descarreguis aquesta versió abans d'usar l'aplicació.",
  },
  {
    title: "Secció de 'Novetats'",
    description:
      "Quan es llancin noves actualitzacions, la primera vegada que accedeixis a l'aplicació, apareixerà una finestra a la pantalla principal amb detalls sobre els canvis. També podràs revisar aquests canvis a través de la secció de 'Últimes actualitzacions' a la pantalla de configuració.",
  },
  {
    title: "Nou disseny millorat i rendiment més ràpid",
    description: "Millorem el disseny i el rendiment per a una navegació més fluida a través de l'aplicació.",
  },
  {
    title: "Barra inferior i nou menú",
    description:
      "Hem eliminat la barra de navegació a la part inferior de la pantalla.\nAra pots accedir a la configuració i altres elements del menú des del calaix que s'obre en fer clic en la icona del menú a la cantonada superior dreta.",
  },
  {
    title: "Avaluacions de docents només a través de sessions",
    description:
      "Les sessions són ara la pantalla predeterminada per a tots/es els/les docents , i només pots avaluar a través de sessions. Si vols avaluar a un/a estudiant, ha d’estar inclòs/a a una sessió, per a poder registrar el “context” en el qual es genera aquesta avaluació de manera correcta.",
  },
  {
    title: "Guardat d'avaluacions",
    description:
      "En realitzar avaluacions, hem canviat la forma en la qual es guarden les avaluacions. Abans es guardaven a través del botó “enrere”; ara tindràs dos botons en la part inferior, botó 'Guardar' per a guardar-les i tornar a la llista d'estudiants, o botó 'Cancel·lar' per a tornar a la llista d'estudiants sense guardar l'avaluació.",
  },
  {
    title: "Agrupació per subgrups",
    description:
      "Millorem la funcionalitat d'agrupació en la llista d'estudiants: els/les estudiants s'agrupen per grups de treball en cas que estiguin assignats a algun.\nSi no hi ha subgrups ala sessió, l'agrupació es realitza per grups.",
  },
  {
    title: "Problema amb comentaris solucionat i millora en la funció de comentaris",
    description:
      "Hem solucionat els problemes en guardar comentaris en les avaluacions de comportaments. La icona de comentari canvia de color una vegada que es proporciona un comentari, perquè ara tinguis una millor visió d'on has proporcionat un comentari i on no.",
  },
  {
    title: "Descripcions de habilitats sota la icona 'i'",
    description:
      "En lloc d'haver d'activar 'Mostrar descripcions' a la Configuració, les descripcions de cada habilitat ara estan disponibles per defecte sota la icona d'informació 'i'. Després de fer clic en aquesta icona, apareixerà la descripció. Pots tancar la descripció tocant a qualsevol lloc fora de la finestra emergent.",
  },
];
