import { isNil } from "lodash";
import React, { createContext, useContext, useMemo, useReducer } from "react";

import { dimensions } from "./dimensions";

export const TestSettingsContext = createContext({});
export const TestStudentsContext = createContext({});
export const TestSessionsContext = createContext({});
export const DimensionsContext = createContext({});
export const VotesContext = createContext({});
export const IntegrationContext = createContext({});

const SETTINGS_PROVIDER_CONST = {
  UNRECOGNIZED_ACTION: "Context must be called within context provider",
  WITHIN_PROVIDER_ERR: "unrecognized action in reducer : ",
};

export const SettingsProvider = ({ children }) => {
  const initialSettings = {
    tags: [],
    drawerAdditionalItems: [],
  };
  const initialSessions = {
    sessions: [],
    studentsToAdd: [],
    sessionData: {
      name: "",
      students: [],
      enabled: false,
      hasSelfEvaluation: true,
    },
  };
  const initialStudents = {
    studentsSearchTerm: "",
    reducedStudentsListForStudentsScreen: null,
    reducedStudentsListForSessionStudentsScreen: null,
    showTags: false,
    showStudentsGroups: false,
  };
  const initialDimensions = {
    dimensions,
    loadingDimensions: false,
    checkedWithBackend: false,
    dimensionsSearchTerm: "",
    reducedDimensions: null,
    showEvidences: {},
    focusedDimensions: [],
    withTranslation: false,
  };
  const initialVotes = {
    allVotes: {},
  };

  const settingsReducer = (settings, action) => {
    switch (action.type) {
      case "setTags":
        return { ...settings, tags: action.payload };
      case "setDrawerAdditionalItems":
        return { ...settings, drawerAdditionalItems: action.payload };
      default:
        throw new Error(SETTINGS_PROVIDER_CONST.WITHIN_PROVIDER_ERR + "settings");
    }
  };
  const sessionsReducer = (sessionsState, action) => {
    switch (action.type) {
      case "setSessions":
        return { ...sessionsState, sessions: action.payload };
      case "setStudentsToAdd":
        return { ...sessionsState, studentsToAdd: action.payload };
      case "setSessionData":
        return { ...sessionsState, sessionData: action.payload };
      default:
        throw new Error(SETTINGS_PROVIDER_CONST.WITHIN_PROVIDER_ERR + "sessions");
    }
  };
  const studentsReducer = (students, action) => {
    switch (action.type) {
      case "setStudentsSearchTerm":
        return { ...students, studentsSearchTerm: action.payload };
      case "setReducedStudentsListForStudentsScreen":
        return {
          ...students,
          reducedStudentsListForStudentsScreen: action.payload,
        };
      case "setReducedStudentsListForSessionStudentsScreen":
        return {
          ...students,
          reducedStudentsListForSessionStudentsScreen: action.payload,
        };
      case "setTagView":
        return { ...students, showTags: action.payload };
      case "toggleTagView":
        return { ...students, showTags: !students.showTags };
      case "toggleGroupView":
        return {
          ...students,
          showStudentsGroups: !students.showStudentsGroups,
        };
      default:
        throw new Error(SETTINGS_PROVIDER_CONST.WITHIN_PROVIDER_ERR + "students");
    }
  };
  const dimensionsReducer = (dimensions, action) => {
    switch (action.type) {
      case "setDimensions":
        return { ...dimensions, dimensions: action.payload };
      case "setLoadingDimensions":
        return {
          ...dimensions,
          loadingDimensions: action.payload,
        };
      case "setCheckedWithBackend":
        return {
          ...dimensions,
          checkedWithBackend: action.payload,
        };
      case "setDimensionsSearchTerm":
        return { ...dimensions, dimensionsSearchTerm: action.payload };
      case "setReducedDimensions":
        return {
          ...dimensions,
          reducedDimensions: action.payload,
        };
      case "setShowEvidences":
        return {
          ...dimensions,
          showEvidences: action.payload,
        };
      case "setFocusedDimensions":
        return {
          ...dimensions,
          focusedDimensions: action.payload,
        };
      case "setWithTranslation":
        return {
          ...dimensions,
          withTranslation: action.payload,
        };

      default:
        throw new Error(SETTINGS_PROVIDER_CONST.WITHIN_PROVIDER_ERR + "dimensions");
    }
  };
  const votesReducer = (votes, action) => {
    switch (action.type) {
      case "setAllVotes":
        return { ...votes, allVotes: action.payload };
      default:
        throw new Error(SETTINGS_PROVIDER_CONST.WITHIN_PROVIDER_ERR + "votes");
    }
  };

  const [settings, settingsDispatch] = useReducer(settingsReducer, initialSettings);
  const [sessionsData, sessionsDispatch] = useReducer(sessionsReducer, initialSessions);
  const [studentsData, studentsDispatch] = useReducer(studentsReducer, initialStudents);
  const [dimensionsData, dimensionsDispatch] = useReducer(dimensionsReducer, initialDimensions);
  const [votes, votesDispatch] = useReducer(votesReducer, initialVotes);

  const settingsStore = useMemo(() => ({ settings, settingsDispatch }), [settings]);
  const sessionsStore = useMemo(() => ({ sessionsData, sessionsDispatch }), [sessionsData]);
  const studentsStore = useMemo(() => ({ studentsData, studentsDispatch }), [studentsData]);
  const dimensionsStore = useMemo(() => ({ dimensionsData, dimensionsDispatch }), [dimensionsData]);
  const votesStore = useMemo(() => ({ votes, votesDispatch }), [votes]);

  return (
    <DimensionsContext.Provider value={{ dimensionsStore }}>
      <TestSettingsContext.Provider value={{ settingsStore }}>
        <TestSessionsContext.Provider value={{ sessionsStore }}>
          <TestStudentsContext.Provider value={{ studentsStore }}>
            <VotesContext.Provider value={{ votesStore }}>{children}</VotesContext.Provider>
          </TestStudentsContext.Provider>
        </TestSessionsContext.Provider>
      </TestSettingsContext.Provider>
    </DimensionsContext.Provider>
  );
};

export const IntegrationProvider = ({ children }) => {
  const integrationReducer = (integrationData, action) => {
    switch (action.type) {
      case "setLogin":
        return { ...integrationData, login: action.payload };
      case "setPassword":
        return { ...integrationData, password: action.payload };
      default:
        throw new Error("unrecognized action in votes reducer");
    }
  };

  const initialIntegration = {
    login: "",
    password: "",
  };

  const [integrationData, integrationDispatch] = useReducer(integrationReducer, initialIntegration);

  const integrationStore = useMemo(() => ({ integrationData, integrationDispatch }), [integrationData]);

  return <IntegrationContext.Provider value={{ integrationStore }}>{children}</IntegrationContext.Provider>;
};

export const useIntegrationContext = () => {
  const {
    integrationStore: { integrationData, integrationDispatch },
  } = useContext(IntegrationContext);

  if (isNil(IntegrationContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  return { integrationData, integrationDispatch };
};

export const useVotesContext = () => {
  const {
    votesStore: {
      votes: { allVotes },
      votesDispatch,
    },
  } = useContext(VotesContext);

  if (isNil(VotesContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  const setAllVotes = (payload) => {
    votesDispatch({
      type: "setAllVotes",
      payload,
    });
  };

  return { allVotes, setAllVotes };
};

export const useTestSettingsContext = () => {
  const {
    settingsStore: { settingsDispatch, settings },
  } = useContext(TestSettingsContext);

  if (isNil(TestSettingsContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  return { settingsDispatch, settings };
};

export const useTestSessionsContext = () => {
  const {
    sessionsStore: { sessionsDispatch, sessionsData },
  } = useContext(TestSessionsContext);

  if (isNil(TestSessionsContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  return { sessionsDispatch, sessionsData };
};

export const useTestStudentsContext = () => {
  const {
    studentsStore: { studentsDispatch, studentsData },
  } = useContext(TestStudentsContext);

  if (isNil(TestStudentsContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  return { studentsDispatch, studentsData };
};

export const useDimensionsContext = () => {
  const {
    dimensionsStore: { dimensionsDispatch, dimensionsData },
  } = useContext(DimensionsContext);

  if (isNil(DimensionsContext)) {
    throw new Error(SETTINGS_PROVIDER_CONST.UNRECOGNIZED_ACTION);
  }

  return { dimensionsDispatch, dimensionsData };
};
