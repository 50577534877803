import { t } from "i18n-js";
import { isNull, orderBy } from "lodash";
import React, { memo, useCallback, useState } from "react";
// import { useLocation } from "../../../hooks/useLocation";
import { Icon, Modal, Popover } from "@ant-design/react-native";
import { Easing, Pressable, Text } from "react-native";

import { useTestSessionsContext } from "../../../providers/SettingsProvider";
import { deleteSession, duplicateSession } from "../../../services";

export const SessionCardMenu = memo(
  ({
    session,
    setRenameModalVisible,
    setShareModalVisible,
    setBehaviourSelectionModalOpen,
    toggleSession,
    placement,
  }) => {
    const {
      sessionsData: { sessions },
      sessionsDispatch,
    } = useTestSessionsContext();
    const [alertOpen, setAlertOpen] = useState(false);

    // const { address } = useLocation();

    const setSessions = (payload) => {
      sessionsDispatch({
        type: "setSessions",
        payload,
      });
    };

    const handleSessionDelete = useCallback(
      () =>
        deleteSession(session.id, sessions).then((newSessions) => {
          if (session.enabled) {
            toggleSession({ location: address });
          }
          setSessions(orderBy(newSessions, "name"));
        }),
      []
    );

    const handleSessionDuplicate = (withAdministrators) => {
      duplicateSession(session, sessions, withAdministrators)
        .then((newSessions) => {
          setSessions(orderBy(newSessions, "name"));
        })
        .then(() => {
          setAlertOpen(false);
        });
    };

    const handleDuplicateSessionModal = () => {
      Modal.alert(
        t("sessions.list.duplicate"),
        t("sessions.list.duplicateQuestion"),
        [
          {
            text: t("no"),
            onPress: () => {
              handleSessionDuplicate(false);
            },
            style: "cancel",
          },
          {
            text: t("yes"),
            onPress: () => {
              handleSessionDuplicate(true);
            },
            style: "danger",
          },
        ]
      );
    };

    let currentElement = 0;

    let closeCallback = null;

    return (
      <Popover
        renderOverlayComponent={(Overlay, close) => {
          if (closeCallback === null) closeCallback = close;

          return Overlay;
        }}
        duration={150}
        easing={(show) => (show ? Easing.in(Easing.ease) : Easing.step0)}
        placement={placement}
        overlay={
          <>
            <Popover.Item value={"rename"}>
              <Pressable
                onPress={(e) => {
                  if (!isNull(closeCallback)) closeCallback();
                  setRenameModalVisible(true);
                }}
              >
                <Text>{t("sessions.list.rename")}</Text>
              </Pressable>
            </Popover.Item>

            <Popover.Item value={"shareTitle"}>
              <Pressable
                onPress={() => {
                  if (!isNull(closeCallback)) closeCallback();
                  setShareModalVisible(true);
                }}
              >
                <Text>{t("sessions.list.shareTitle")}</Text>
              </Pressable>
            </Popover.Item>

            <Popover.Item value={"duplicate"}>
              <Pressable
                onPress={() => {
                  if (!isNull(closeCallback)) closeCallback();
                  handleDuplicateSessionModal();
                }}
              >
                <Text>{t("sessions.list.duplicate")}</Text>
              </Pressable>
            </Popover.Item>

            <Popover.Item value={"title"}>
              <Pressable
                onPress={() => {
                  if (!isNull(closeCallback)) closeCallback();
                  setBehaviourSelectionModalOpen(true);
                }}
              >
                <Text>{t("sessions.selectBehaviours.title")}</Text>
              </Pressable>
            </Popover.Item>

            <Popover.Item value={"delete"}>
              <Pressable
                onPress={() => {
                  if (!isNull(closeCallback)) closeCallback();
                  handleSessionDelete();
                }}
              >
                <Text>{t("delete")}</Text>
              </Pressable>
            </Popover.Item>
          </>
        }
      >
        <Icon name="more" size="md" color="#000" style={{ marginRight: 3 }} />
      </Popover>
    );
  }
);
