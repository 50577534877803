import React, { useCallback, useContext, useEffect, useState } from "react";

import { StudentItemSelectable } from "../../components/studentShared/StudentItemSelectable";

import { doc, getDoc } from "firebase/firestore";

import { Button, Icon, Toast, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import { FlatList, Text, StyleSheet } from "react-native";

import { SearchBar, auth, store } from "../../components";
import { AuthenticatedUserContext } from "../../providers";

import { Loading } from "../../components/Loading";

import { t } from "i18n-js";
import { filter, find, includes, isNil, isNull } from "lodash";

import { signInWithCustomToken } from "firebase/auth";
import { logInStudentToSession, sendNotificationToUser, getCountryWhereSessionWasEnabled } from "../../services/SessionsService";
import { Nullable } from "../..";
import { EmptyView } from "./EmptyView";
import { EmptyList } from "./EmptyList";
import { useLocation } from "../../hooks/useLocation";

enum AuthenticationListConstants {
  SESSION_COLLECTION = "sessions",
  FIREBASE_FUNCTIONS_TOKEN = "c1ac2d20-19a3-401f-a3a1-f5f29ef91ba4",
  CLAIMED_ERROR = "UNAUTHORIZED OR ALREADY CLAIMED",
  INVALID_LOCATION = "Invalid Location",
}

interface StudentInterface {
  email: string;
  name: string;
  group: string;
  timestamp: number;
  key: string;
  [keyof: string]: any;
}

type getSessionReturnType = Promise<{ students: StudentInterface[]; owners: string[] } | false>;

const getSessionData = async (sessionId: string): getSessionReturnType => {
  try {
    const sessionRef = doc(store, AuthenticationListConstants.SESSION_COLLECTION, sessionId);
    const sessionSnapshot = await getDoc(sessionRef);
    const sessionData = sessionSnapshot.data();

    if (sessionSnapshot.exists) {
      return { students: sessionData.students, owners: sessionData.users };
    }

    throw new Error("snapshot do not exist");
  } catch (err) {
    return false;
  }
};

const SessionStudentsAuthenticationList = ({ navigation }): JSX.Element => {
  const { accessedSessionId }: { accessedSessionId?: string } = useContext(AuthenticatedUserContext);
  const { address } = useLocation();

  const [sessionStudents, setSessionStudents] = useState<StudentInterface[]>([]);
  const [sessionOwners, setSessionOwners] = useState<string[]>([]);

  const [selectedUserId, setSelectedUserId] = useState<Nullable<string>>(null);

  const [textInput, setTextInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const handleSelectItem = (id: string): void => {
    if (selectedUserId !== id) setSelectedUserId(id);
    else setSelectedUserId(null);
  };

  const handleInputReset = (): void => setTextInput("");

  const handleLogInStudent = async (): Promise<number | boolean> => {
    try {
      setIsRequestPending(true);

      const country = address ? address[0].country : null;
      const sessionLocationProperty = await getCountryWhereSessionWasEnabled(accessedSessionId);

      if (isNull(country) && country !== sessionLocationProperty) {
        throw new Error(AuthenticationListConstants.INVALID_LOCATION);
      }

      const { authToken } = await logInStudentToSession({
        token: AuthenticationListConstants.FIREBASE_FUNCTIONS_TOKEN,
        sessionId: accessedSessionId,
        selectedStudentId: selectedUserId,
      });

      await signInWithCustomToken(auth, authToken);
    } catch (err) {
      if (includes(err.message, AuthenticationListConstants.CLAIMED_ERROR)) {
        const student = find(sessionStudents, ({ key }) => key === selectedUserId);

        sendNotificationToUser({
          title: t("login.students.notificationErrorTitle"),
          body: student.name + " " + t("login.students.notificationErrorBody"),
          token: AuthenticationListConstants.FIREBASE_FUNCTIONS_TOKEN,
          usersId: sessionOwners,
        });

        return Toast.fail({
          content: t("login.students.claimedError"),
          duration: 1,
        });
      } else if (includes(err.message, AuthenticationListConstants.INVALID_LOCATION)) {
        sendNotificationToUser({
          title: t("login.students.notificationErrorTitle"),
          body: t("login.students.notificationErrorLocationBody"),
          token: AuthenticationListConstants.FIREBASE_FUNCTIONS_TOKEN,
          usersId: sessionOwners,
        });

        return Toast.fail({
          content: t("login.students.locationError"),
          duration: 1,
        });
      } else {
        return Toast.fail({
          content: "unregonized error",
          duration: 1,
        });
      }
    } finally {
      setIsRequestPending(false);
    }
  };

  const handleChangeTextInput = (e: string): void => {
    if (e === "") setSelectedUserId(null);
    setTextInput(e);
  };
  const flatListData: any[] = textInput.length >= 3 ? filter(sessionStudents, (student: any) => includes(student.name.toLowerCase(), textInput.toLowerCase())) : [];

  const renderItem = useCallback(
    ({ item }) => {
      const studentItemSelctableProps = {
        item,
        avatarColor: selectedUserId === item.key ? "orange" : false,
        onPress: () => handleSelectItem(item.key),
      };
      return <StudentItemSelectable {...studentItemSelctableProps} />;
    },
    [selectedUserId]
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      // todo add interface
      const res = await getSessionData(accessedSessionId);

      if (res) {
        setSessionStudents(res.students);
        setSessionOwners(res.owners);
      }

      setLoading(false);
    };

    const unsubscribe = navigation.addListener("focus", fetchData);
    return unsubscribe;
  }, []);

  if (loading) return <Loading />;
  if (isNil(accessedSessionId)) return <EmptyView navigation={navigation} />;

  return (
    <View style={styles.full}>
      <WhiteSpace size="xl" />
      <WingBlank size="md">
        <Text style={styles.heading}>{t("login.students.selectIdentity")}</Text>
      </WingBlank>
      <WhiteSpace size="md" />
      <SearchBar onChangeText={handleChangeTextInput} showClose={false} />

      <FlatList data={flatListData} ListEmptyComponent={<EmptyList />} contentContainerStyle={styles.listContainer} renderItem={renderItem} />

      <Button style={styles.button} type="primary" disabled={selectedUserId === null} onPress={handleLogInStudent} loading={isRequestPending}>
        <Icon name="save" color="white" />
        <WingBlank size="sm" />
        <Text style={styles.white}>{t("save")}</Text>
      </Button>
      <WhiteSpace size="lg" />
    </View>
  );
};

const styles = StyleSheet.create({
  full: {
    width: "100%",
    height: "100%",
    paddingVertical: 20,
  },
  button: {
    position: "absolute",
    bottom: 5,
    left: "1%",
    width: "98%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 24,
  },
  listContainer: {
    paddingBottom: 280,
    flexGrow: 1,
  },
  white: {
    color: "white",
  },
});

export default SessionStudentsAuthenticationList;
