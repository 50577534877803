import { findIndex, isNil, isNull, isUndefined } from "lodash";
import React, { memo } from "react";
import { VotesContext, useTestSettingsContext, useVotesContext } from "../../../providers/SettingsProvider";
import { MemoizedModal } from "../../../screens/SessionStack/SessionUserEvaluation/MemoizedModal";

export const withVotesSettingsContext = (Component) => {
  const Memoized = memo(Component);

  return (props) => {
    const {
      settings: { tags },
    } = useTestSettingsContext();
    const { allVotes, setAllVotes } = useVotesContext(VotesContext);

    return <Memoized {...props} tags={tags} allVotes={allVotes} setAllVotes={setAllVotes} />;
  };
};

export const ModalWithDimensionsContext = withVotesSettingsContext(({ setAllVotes, allVotes, category, session, comment, setComment, setModalVisible, modalVisible, tags }) => {
  const handleSave = (evidence, value) => {
    const newVotes = { ...allVotes };

    if (isUndefined(newVotes[category])) {
      newVotes[category] = {};
    }

    newVotes[category][evidence] = {
      ...(newVotes[category][evidence] || {}),
      tags: [...tags],
      timestamp: Math.round(+new Date() / 1000),
      ...value,
    };

    // Replace the tags with session if evaluation is started from a session

    if (!isNil(session) && findIndex(newVotes[category][evidence].tags, (t) => t.includes("session")) === -1) {
      newVotes[category][evidence].tags.push(`session:${session.name}:${session.id}`);
    }

    if (value.value === 0) delete newVotes[category][evidence];
    if (value.comment === "") delete newVotes[category][evidence].comment;

    if (
      (newVotes[category][evidence].comment === "" || isNil(newVotes[category][evidence][comment])) &&
      (newVotes[category][evidence].value === 0 || isNull(newVotes[category][evidence].value))
    )
      delete newVotes[category][evidence];

    setAllVotes(newVotes);
  };

  return <MemoizedModal modalVisible={modalVisible} handleSave={handleSave} comment={comment} setComment={setComment} setModalVisible={setModalVisible} category={category} />;
});
