import React, { useContext } from "react";

import { StyleSheet } from "react-native";

import { updateUserProfile } from "../services";

import { AuthenticatedUserContext } from "../providers";

import { localizeInit } from ".";

import { Button, Flex, WingBlank } from "@ant-design/react-native";

interface ContextProps {
  [keyof: string]: any;
}

export const LanguageButtonGroup = (): JSX.Element => {
  const { setLanguage, userProfile, setUserProfile }: ContextProps = useContext(
    AuthenticatedUserContext
  );

  const styles = StyleSheet.create({
    button: {
      width: "25%",
    },
    maxWidth: {
      width: "100%",
    },
  });

  const handleUserProfileUpdate = (
    field: string,
    value: string | boolean = false
  ): void => {
    const newUserProfile = { ...userProfile };
    newUserProfile.profile[field] = value;

    updateUserProfile(newUserProfile.profile)
      .then(() => {
        setUserProfile(newUserProfile);
      })
      .catch((err) => {});
  };

  const handleLanguageChange = (value: string): void => {
    try {
      setLanguage(value);
      localizeInit(value);
      handleUserProfileUpdate("language", value);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Flex justify="center" style={styles.maxWidth}>
      <Button
        type={"ghost"}
        style={styles.button}
        onPress={() => handleLanguageChange("en")}
      >
        EN
      </Button>
      <WingBlank size="sm" />
      <Button
        type={"ghost"}
        style={styles.button}
        onPress={() => handleLanguageChange("es")}
      >
        ES
      </Button>
      <WingBlank size="sm" />
      <Button
        type={"ghost"}
        style={styles.button}
        onPress={() => handleLanguageChange("ca")}
      >
        CA
      </Button>
      <WingBlank size="sm" />
      <Button
        type={"ghost"}
        style={styles.button}
        onPress={() => handleLanguageChange("pt")}
      >
        PT
      </Button>
    </Flex>
  );
};
