import React, { useState } from "react";

import { Flex, Icon, View } from "@ant-design/react-native";
import { Platform, StyleSheet, TextInput } from "react-native";
import { Nullable } from "..";
import { isNil, keys } from "lodash";
import { useDimensionsContext } from "../providers/SettingsProvider";
import { t } from "../components";

interface SearchBarProps {
  onChangeText(text: string): void;
  showClose?: false;
  action?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onChangeText = null, action = null }) => {
  const [inputValue, setInputValue] = useState<Nullable<string>>(null);
  const {
    dimensionsData: { dimensions },
    dimensionsDispatch,
  } = useDimensionsContext();

  const setReducedDimensions = (payload) => {
    dimensionsDispatch({
      type: "setReducedDimensions",
      payload,
    });
  };

  const handleInputValueChange = (text: string): void => {
    setInputValue(text);
    if (!isNil(onChangeText)) onChangeText(text);
    else if (action === "searchDimensions") searchDimensions(text);
  };

  const searchDimensions = (text: string) => {
    let newDimensions = {};

    keys(dimensions).forEach((category) => {
      const filteredEvidences = keys({ ...dimensions[category].dimensions })
        .filter((evidence) => !evidence.includes("otherComments"))
        .filter((evidence) => t(`evaluation.${category}.dimensions.${evidence}`).toLowerCase().includes(text.toLowerCase()));

      if (filteredEvidences.length > 0) {
        newDimensions[category] = {};
        newDimensions[category].title = dimensions[category].title;
        newDimensions[category].description = dimensions[category].description;
        newDimensions[category].dimensions = {};
        filteredEvidences.forEach((ev) => {
          newDimensions[category].dimensions[ev] = dimensions[category].dimensions[ev];
        });
      }
    });

    setReducedDimensions(newDimensions);
  };

  return (
    <View style={styles.searchBarContainer}>
      <Flex style={styles.searchBarFlex}>
        <Icon name="search" style={styles.icon} />
        <TextInput style={styles.searchBar} value={inputValue} onChangeText={handleInputValueChange} />
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginRight: 5,
  },
  searchBarContainer: {
    width: Platform.OS === "web" ? "35%" : "100%",
    marginBottom: 5,
  },
  searchBarFlex: {
    borderRadius: 5,
    marginHorizontal: 7,
    marginVertical: 15,
    paddingVertical: 3,
    paddingHorizontal: 5,
    backgroundColor: "#f0f2f5",
  },
  searchBar: {
    width: "90%",
    paddingHorizontal: 3,
  },
});
