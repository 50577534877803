import React from "react";

import { Modal, View, WhiteSpace } from "@ant-design/react-native";
import { Linking, Text } from "react-native";

import { t } from "i18n-js";

const UpdateVersionScreen = ({ route }) => {
  const { storeUrl } = route.params;

  const handleGoToStore = () => Linking.openURL(storeUrl);

  return (
    <Modal
      transparent={true}
      popup={true}
      maskClosable={true}
      visible={true}
      title={t("update.title")}
      footer={[{ text: t("update.button"), onPress: handleGoToStore }]}
    >
      <WhiteSpace size="md" />
      <View>
        <Text>{t("update.desc")}</Text>
      </View>
    </Modal>
  );
};

export default UpdateVersionScreen;
