import { Toast } from "@ant-design/react-native";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { isNil } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { FlatList, RefreshControl } from "react-native";
import { getColor, store, t } from "../../../components";
import { StudentItemSelectable } from "../../../components/studentShared/StudentItemSelectable";
import { useTestSettingsContext } from "../../../providers/SettingsProvider";
import { EmptyList } from "./EmptyList";

export const StudentsList = memo(({ reducedStudentsListForStudentsScreen, allStudents, groupList, navigate }) => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);
  const {
    settings: { tags },
  } = useTestSettingsContext();

  const navigateToUserEvaluation = (item) => {
    if (!isNil(currentSession) && currentSession.enabled) navigate("UserEvaluation", { student: item, session: currentSession });
    else
      Toast.fail({
        duration: 3,
        content: t("sessions.haveToBeEnabled"),
      });
  };

  const renderItem = useCallback(
    ({ item, index }) => {
      return <StudentItemSelectable item={item} avatarColor={getColor(item.group, groupList)} onPress={() => navigateToUserEvaluation(item)} />;
    },
    [currentSession]
  );

  useEffect(() => {
    const sessionId = tags.length > 0 ? tags[0].split(":")[2] : null;

    if (sessionId)
      onSnapshot(doc(store, `sessions/${sessionId}`), (snap) => {
        const data = snap.data();

        if (!data) setCurrentSession(null);
        setCurrentSession(data);
      });

    if (loading) {
      setLoading(false);
    }

    if (listData !== reducedStudentsListForStudentsScreen && listData !== allStudents) {
      setListData(reducedStudentsListForStudentsScreen ? reducedStudentsListForStudentsScreen : allStudents);
    }
  }, [loading, reducedStudentsListForStudentsScreen, listData]);

  return (
    <FlatList
      data={listData}
      ListEmptyComponent={<EmptyList />}
      contentContainerStyle={{ paddingBottom: 280 }}
      refreshing={loading}
      refreshControl={<RefreshControl refreshing={loading} onRefresh={() => listData} />}
      renderItem={renderItem}
      numColumns={1}
      initialNumToRender={9}
    />
  );
});
