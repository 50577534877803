import React, { useCallback, useEffect, useState } from "react";
import { FlatList } from "react-native";
import { FocusedBehaviour } from "./FocusedBehaviour";

export const FocusedBehaviourList = ({ selectedBehaviours, session }) => {
  const [listData, setListData] = useState([]);

  const renderItem = useCallback(
    ({ item, index }) => {
      return <FocusedBehaviour behaviour={item} session={session} key={index} />;
    },
    [selectedBehaviours]
  );

  useEffect(() => {
    if (listData !== selectedBehaviours) {
      setListData(selectedBehaviours);
    }
  }, [selectedBehaviours, session]);

  return (
    <FlatList
      data={listData}
      renderItem={renderItem}
      keyExtractor={() => `focusedBehaviour_item_` + Math.floor(Math.random() * 100000)}
      initialNumToRender={selectedBehaviours?.length ?? 1}
      maxToRenderPerBatch={3}
    />
  );
};
