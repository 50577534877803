import { Flex, Text, View, WhiteSpace, WingBlank } from "@ant-design/react-native";
import React, { memo, useState } from "react";
import { includes } from "lodash";
import { List } from "@ant-design/react-native";

import UserAvatar from "react-native-user-avatar";
import { Platform } from "react-native";

export const StudentItemSelectable = memo(({ item, avatarColor, onPress }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <List.Item
      thumb={
        <UserAvatar
          name={item.name
            .split(" ")
            .map((n) => n[0])
            .join("")
            .toUpperCase()}
          bgColor={avatarColor ? avatarColor : isChecked ? "orange" : "#d1d1d1"}
        />
      }
      onPress={() => {
        setIsChecked((prev) => !prev);
        onPress();
      }}
    >
      <View style={{ paddingTop: 2, paddingRight: 5, paddingBottom: 2, paddingLeft: 4, width: "100%" }}>
        <Flex justify={"between"} align="center">
          <View w="100%">
            <Flex justify="start">
              <WingBlank size="md" />
              <Flex direction="column" justify="center" align="start" style={{ width: Platform.OS === "web" ? "100%" : "90%" }}>
                <Text style={{ fontWeight: "bold", width: Platform.OS === "web" ? "100%" : "90%" }} numberOfLines={1}>
                  {item.name}
                </Text>
                <Text numberOfLines={1} style={{ width: Platform.OS === "web" ? "100%" : "90%" }}>
                  {includes(item.group.trim(), "\\") ? item.group.split("\\").join(" | ") : item.group.trim()}
                </Text>
              </Flex>
            </Flex>
          </View>
          <WhiteSpace size="sm" />
        </Flex>
      </View>
    </List.Item>
  );
});
