import { Button, Flex, Icon, Modal, View, WhiteSpace } from "@ant-design/react-native";
import React, { useCallback, useState } from "react";
import { Dimensions, Text } from "react-native";
import { t } from "../../../components";
import { FeedbackModal } from "../../../components/Feedback/Modal/FeedbackModal";
import CustomizedTextAreaItem from "../../../components/Inputs/CustomizedTextAreaItem";
import { useDimensionsContext } from "../../../providers/SettingsProvider";
import CustomizedInputItem from "../../../components/Inputs/CustomizedInputItem";

export const MemoizedModal = ({ modalVisible, handleSave, comment, setComment, setModalVisible, category }) => {
  const {
    dimensionsData: { withTranslation, dimensions: defaultDimensions },
  } = useDimensionsContext();
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  // pending|success|error
  const [feedbackStatus, setFeedbackStatus] = useState("pending");

  const isWindowWidthSmall = Dimensions.get("window").width < 400;
  const isWindowHeightSmall = Dimensions.get("window").height < 700;

  const handleModalClose = useCallback(() => {
    setComment({ evidence: null, comment: "" });
    setModalVisible(false);
  }, []);

  const onCommentChangeText = (text) => {
    const updatedComment = { ...comment };
    updatedComment.comment = text;
    setComment(updatedComment);
  };

  const handleSaveComment = async () => {
    try {
      handleSave(comment.evidence, { comment: comment.comment });
      setFeedbackStatus("success");
      setIsFeedbackModalVisible(true);
      handleModalClose();
    } catch (err) {
      setFeedbackStatus("error");
    }
  };

  return (
    <>
      <FeedbackModal
        visible={isFeedbackModalVisible}
        setModalVisible={setIsFeedbackModalVisible}
        pendingMessage={t("updatesHandling.saving")}
        successMessage={t("updatesHandling.commentAdded")}
        errorMessage={t("updatesHandling.commentAddError")}
        status={feedbackStatus}
      />
      <Modal
        transparent={true}
        // {...customProps}
        visible={modalVisible}
        onClose={handleModalClose}
        maskClosable={true}
        animationType={"slide"}
        title={
          !withTranslation && comment.evidence
            ? t(`evaluation.${category}.dimensions.${comment.evidence}`)
            : withTranslation && modalVisible && comment.evidence && defaultDimensions[category].dimensions[comment.evidence][`label_${userProfile.profile.language || "en"}`]
        }
      >
        <WhiteSpace size="md" />
        <View>
          {isWindowHeightSmall && isWindowWidthSmall ? (
            <CustomizedInputItem onChangeText={onCommentChangeText} defaultValue={comment.comment} />
          ) : (
            <CustomizedTextAreaItem onChangeText={onCommentChangeText} defaultValue={comment.comment} />
          )}
        </View>
        <WhiteSpace size="md" />
        <Button onPress={handleSaveComment}>
          <Flex>
            <Icon name="save" size={"sm"} />
            <Text>{t("save")}</Text>
          </Flex>
        </Button>
      </Modal>
    </>
  );
};
