import { isEmpty, isNil } from 'lodash';

const handleIframeRedirection = (window, document) => {
  if (window.location == window.parent.location) {
    if (isEmpty(document.referrer) || isNil(document.referrer)) {
      location.reload();
    }
    switch (document.referrer) {
      case 'http://localhost:19006/':
        window.location.href = 'http://localhost:3000';
        break;
      case 'https://pentabilities-mobile-develop.web.app/':
        window.location.href = 'https://pentabilities-layout-develop.web.app/';
      case 'https://mobile.pentabilities.com/':
        window.location.href = 'https://platform.pentabilities.com/';
      default:
        window.location.href = 'https://platform.pentabilities.com/';
        break;
    }
  }
};

export { handleIframeRedirection };
