export const dimensions = {
  responsiblity: {
    title: "Responsibility",
    description: "Performs the tasks, trying to accomplish the set goals",
    dimensions: {
      worksConstant: "Works in a constant manner",
      keepsConnected: "Keeps connected to the group activity",
      commentsRelated:
        "The comments and/or activity he/she is doing are related with the project",
      performsEfficiently: "Performs the tasks efficiently",
      performsCare: "Performs the tasks with care",
      perserveres: "Perseveres in front of difficulties",
      respects: "Respects rules",
      otherComments: "General Comment",
    },
  },
  cooperation: {
    title: "Cooperation",
    description:
      "listens and participates to take common decisions and solve conflicts",
    dimensions: {
      listensPeers: "Listens to peers and teachers",
      incorporatesProposals: "Incorporates what peers and teachers propose",
      encouragesPeers: "Encourages peers’ participation",
      engages: "Engages in group decision making",
      facilitates: "Facilitates conflict resolution ",
      recognisesResponsiblities:
        "Recognises his/her responsabilities and those of others",
      isKind: "He/she is kind to others",
      otherComments: "General Comment",
    },
  },
  autonomy: {
    title: "Autonomy and Initiative",
    description:
      "works without need of supervision, and asks for help when required. Brings up ideas and provides solutions when needed.",
    dimensions: {
      bringsIdeas: "Brings up ideas",
      asksQuestions: "Asks questions when he/she stalls",
      isActive: "Plays an active role in group decision making",
      isConvincing: "He knows how to convince his peers of his/her approaches",
      works: "Works with grit",
      isInitiative: "Believes that he/she can initiate changes",
      otherComments: "General Comment",
    },
  },
  emotion: {
    title: "Emotion management",
    description:
      "He is aware of his/her own emotions and those of others, and he manages them well",
    dimensions: {
      isCheerful: "Is cheerful",
      remainsCalm: "Remains calm in stressful situations",
      controlsEmotions: "Controls emotions when conflict arises",
      acceptsFailure: "Accepts failure",
      acceptsApproaches: "Accepts that his/her approaches do not prosper",
      isAdaptive: "Adapts his/her behavior to the circumstances",
      otherComments: "General Comment",
    },
  },
  thinking: {
    title: "Thinking abilities",
    description:
      "Open to intellectual, cultural and/or aesthetic stimuli. Intellectual inquiry, generation of ideas and metacognition skills",
    dimensions: {
      relatesContents: "Relates new contents with previous knowledge",
      reflectsContents: "Makes good reflections on the content",
      reflectsProcesses: "Makes good reflections on internal processes",
      asksGoodQuestions:
        "Asks good questions (to clarify or to deepen learning)",
      hasIdeas:
        "Has creative ideas (in the sense that it explores alternative paths)",
      plansStrategies: "Plans good strategies for problem solving",
      plansTasks: "Plans and prioritizes tasks",
      discoverPerspectives: "Willing to discover different perspectives",
      expressesIdeas:
        "Expresses ideas effectively (correctness, precision and structure)",
      otherComments: "General Comment",
    },
  },
};
