import React, { useCallback, useContext, useEffect, useState } from "react";

import { Platform, ScrollView, Text } from "react-native";

import { signOut } from "firebase/auth";

import { auth } from "../components/Firebase";

import * as WebBrowser from "expo-web-browser";

import {
  ActionSheet,
  Button,
  Card,
  Flex,
  Icon,
  Switch,
  Toast,
  View,
  WhiteSpace,
  WingBlank,
} from "@ant-design/react-native";
import { localizeInit, t } from "../components";
import { AuthenticatedUserContext } from "../providers";
import { updateUserProfile } from "../services";

import { useFocusEffect } from "@react-navigation/native";
import { useTestSettingsContext } from "../providers/SettingsProvider";
import IssueReportModal from "./IssueReportModal";

export const SettingsScreen = ({ navigation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userProfile, setUserProfile, setLanguage, setUser } = useContext(AuthenticatedUserContext);

  const { settingsDispatch } = useTestSettingsContext();

  const setDrawerAdditionalItems = (payload) => {
    settingsDispatch({
      type: "setDrawerAdditionalItems",
      payload,
    });
  };

  const handleUserProfileUpdate = (field, value = false) => {
    const newUserProfile = { ...userProfile };
    newUserProfile.profile[field] = value;

    updateUserProfile(newUserProfile.profile)
      .then(() => {
        setUserProfile(newUserProfile);

        Toast.success({
          content: t("saveSuccess"),
          duration: 1,
        });
      })
      .catch((err) => {
        Toast.fail({
          content: t("error"),
          duration: 1,
        });
      });
  };

  const handleLanguageChange = (value) => {
    handleUserProfileUpdate("language", value);
    setLanguage(value);
    localizeInit(value);
  };

  const showLangActionSheet = () => {
    const BUTTONS = ["EN", "ES", "CA", "PT", t("cancel")];

    ActionSheet.showActionSheetWithOptions(
      {
        title: t("settings.language"),
        options: BUTTONS,
        cancelButtonIndex: 4,
      },
      (buttonIndex) => {
        if (typeof BUTTONS[buttonIndex] === "string" && BUTTONS[buttonIndex] !== t("cancel"))
          handleLanguageChange(BUTTONS[buttonIndex].toLowerCase());
      }
    );
  };
  const handleSignOut = async () => {
    try {
      await signOut(auth).then(() => setUser(null));
    } catch (err) {
      console.warn(err);
    }
  };

  useFocusEffect(
    useCallback(() => {
      setDrawerAdditionalItems([]);
    }, [])
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("drawerItemPress", (e) => {
      e.preventDefault();
    });

    return unsubscribe;
  }, [navigation]);
  return (
    <ScrollView>
      <IssueReportModal isDrawerOpen={isModalOpen} setIsDrawerOpen={setIsModalOpen} fromErrorView={true} />
      <View>
        <ScrollView>
          <View>
            {navigation.getState().routeNames[0] === "sessions" && (
              <>
                <WhiteSpace size="lg" />
                <WingBlank size="lg">
                  <Card>
                    <Card.Header title={t("sessions.title")} />
                    <Card.Footer
                      content={t("settings.showSessionStats")}
                      extra={
                        <Flex justify="end">
                          <Switch
                            onChange={() =>
                              handleUserProfileUpdate("showSessionStats", !userProfile.profile.showSessionStats)
                            }
                            checked={userProfile.profile.showSessionStats || false}
                          />
                        </Flex>
                      }
                    />
                  </Card>
                </WingBlank>
              </>
            )}
            <WhiteSpace size="lg" />

            <WingBlank size="lg">
              <Card>
                <Card.Header
                  title={t("issueReport.title")}
                  extra={
                    <Flex justify="end">
                      <Button onPress={() => setIsModalOpen(true)} type="warning">
                        <Icon name="phone" color="white" />
                      </Button>
                    </Flex>
                  }
                />
              </Card>
            </WingBlank>
            <WhiteSpace size="lg" />
            <WingBlank size="lg">
              <Card>
                <Card.Header
                  title={t("settings.languageHeader")}
                  extra={
                    <Flex justify="end">
                      <Button onPress={showLangActionSheet} type="primary">
                        <Icon name="edit" color="white" />
                      </Button>
                    </Flex>
                  }
                />
              </Card>
            </WingBlank>
            <WhiteSpace size="lg" />
            <WingBlank size="lg">
              <Card>
                <Card.Header
                  title={t("latestUpdates")}
                  extra={
                    <Flex justify="end">
                      <Button type="ghost" onPress={() => navigation.navigate("changelog")}>
                        <Icon name="info-circle" />
                      </Button>
                    </Flex>
                  }
                />
              </Card>
            </WingBlank>
            <WhiteSpace size="lg" />
            <View>
              {Platform.OS === "web" && (
                <WingBlank size="lg">
                  <Flex justify="end">
                    <Button onPress={() => WebBrowser.openBrowserAsync("https://pentabilities.com/terms")}>
                      <Flex>
                        <Text style={{ color: "black" }}>{t("settings.terms")}</Text>
                        <WingBlank size="sm" />
                        <Icon name="file-text" color="black" />
                      </Flex>
                    </Button>
                    <WingBlank size="md" />
                    <Button onPress={handleSignOut} type="warning">
                      <Flex>
                        <Text style={{ color: "white" }}>{t("settings.signOut")}</Text>
                        <WingBlank size="sm" />
                        <Icon name="logout" color="white" />
                      </Flex>
                    </Button>
                  </Flex>
                  <WhiteSpace size="md" />
                </WingBlank>
              )}
              <WingBlank size="md">
                <Text style={{ fontWeight: "bold" }}>{t("settings.info").toUpperCase()}</Text>
                <WhiteSpace size="sm" />
                <Flex justify="between">
                  <Text>{t("settings.buildNumber")}:</Text>
                  <Text style={{ fontWeight: "bold" }}>5.1.9</Text>
                </Flex>
              </WingBlank>
            </View>
          </View>
        </ScrollView>
      </View>
    </ScrollView>
  );
};
