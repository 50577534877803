import React, { memo, useCallback, useContext } from "react";

import { Platform, StyleSheet } from "react-native";

import { Button, Flex, Toast, View, WingBlank } from "@ant-design/react-native";
import { t } from "../../../components";

import { useFocusEffect, useNavigation } from "@react-navigation/native";

import { useDimensionsContext, useTestSettingsContext, useVotesContext } from "../../../providers/SettingsProvider";

import { find, includes, isEmpty, isNil, keys, some } from "lodash";

import { saveEvidence } from "../../../services";

import { httpsCallable } from "firebase/functions";

import { auth, functions } from "../../../components";
import { AuthenticatedUserContext } from "../../../providers";

export const SaveButtonsStack = memo(
  ({
    route: {
      params: { session, student },
    },
    setIsLoading,
    isLoading,
  }) => {
    const navigation = useNavigation();

    const { isResearch, userProfile } = useContext(AuthenticatedUserContext);

    const { dimensionsDispatch } = useDimensionsContext();
    const { allVotes, setAllVotes } = useVotesContext();
    const {
      settings: { tags },
    } = useTestSettingsContext();

    const isEvaluationMaked = keys(allVotes).length > 0 && some(allVotes, (vote) => !isEmpty(vote));

    const styles = StyleSheet.create({
      container:
        Platform.OS === "web"
          ? { width: "100%", height: "10%", margin: "auto" }
          : {
              position: "absolute",
              width: "100%",
              height: "7%",
              left: 0,
              bottom: 0,
            },
      cancel: { width: "45%" },
      fullWidth: { width: "100%" },
      save: {
        width: "45%",
        opacity: isEvaluationMaked ? 1 : 0.95,
      },
    });
    const setReducedDimensions = (payload) => {
      dimensionsDispatch({
        type: "setReducedDimensions",
        payload,
      });
    };

    const saveVotesToBackend = useCallback(() => {
      var votesList = [];

      const promises = [];

      keys(allVotes).forEach(async (category) => {
        keys(allVotes[category]).forEach(async (evidence) => {
          let newEvidence = {
            uid: auth.currentUser.uid,
            group: student.group || "",
            ...allVotes[category][evidence],
          };
          votesList.push({
            student: {
              ...student,
              key: student.key.replace("sub_", "").replace("self_", "").replace("co_", ""),
            },
            destination: { category, evidence },
            newEvidence,
          });

          const sessionFromTags = (() => {
            if (tags.length === 0 || !isNil(session)) return;
            const tmp = find(tags, (t) => includes(t, "session")).split(":");

            return {
              id: tmp[2],
              statEntry: tmp[3],
            };
          })();
          promises.push(
            saveEvidence(
              {
                ...student,
                key: isResearch ? `research_${student.key}` : student.key,
              },
              { category, evidence },
              allVotes[category][evidence],
              userProfile.profile.tenant,
              !isNil(session) ? session : { id: sessionFromTags.id, statEntry: sessionFromTags.statEntry }
            )
          );
        });
      });

      if (keys(allVotes).length > 0) {
        const updateTenantStatistics = httpsCallable(functions, "updateTenantStatistics");

        promises.push(
          updateTenantStatistics(votesList).catch((err) =>
            analytics().logEvent("updateTenantStatistics", {
              status: false,
              error: err.message,
            })
          )
        );
      }

      setIsLoading(true);

      Promise.all(promises)
        .then(() => {
          Toast.success({
            content: t("saveSuccess"),
            duration: 3,
          });
        })
        .catch(() => {
          Toast.fail({
            content: t("saveError"),
            duration: 1,
          });
        });

      setIsLoading(false);
      navigation.goBack();
      setAllVotes({});
    }, [allVotes, userProfile, session]);

    useFocusEffect(
      useCallback(() => {
        return () => {
          setAllVotes({});
          setReducedDimensions(null);
        };
      }, [])
    );

    return (
      <View style={styles.container}>
        <Flex justify="center" style={styles.fullWidth}>
          <WingBlank size="md" />
          <Button style={styles.cancel} onPress={navigation.goBack}>
            {t("cancel")}
          </Button>
          <WingBlank size="md" />
          <Button
            style={styles.save}
            disabled={!isEvaluationMaked || isLoading}
            onPress={saveVotesToBackend}
            type="primary"
          >
            {t("save")}
          </Button>
          <WingBlank size="md" />
        </Flex>
      </View>
    );
  }
);
