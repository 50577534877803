import React from "react";
import { StyleSheet } from "react-native";

import { Text, WhiteSpace, WingBlank } from "@ant-design/react-native";

import { View } from "@ant-design/react-native";
import { includes, isNil } from "lodash";
import SlideIn from "../../components/animations/SlideIn";

interface AccordionContentProps {
  content: string;
  activeSections: number[];
  sectionId: number;
}

const styles = StyleSheet.create({
  content: {
    maxWidth: "90%",
    paddingLeft: 15,
  },
});

export const AccordionContent = ({
  content,
  activeSections,
  sectionId,
}: AccordionContentProps): JSX.Element => {
  if (!includes(activeSections, sectionId)) return <></>;

  return (
    <SlideIn>
      <View style={styles.content}>
        <WhiteSpace size="sm" />
        <WingBlank size="lg">
          <Text style={{ textAlign: "justify" }}>
            {!isNil(content) ? content.trim() : ""}
          </Text>
        </WingBlank>
      </View>
    </SlideIn>
  );
};
