import { Flex, Icon, View, WhiteSpace } from "@ant-design/react-native";
import React, { useEffect } from "react";
import { StyleSheet, Text } from "react-native";

enum SuccessViewConstants {
  icon = "check-circle",
  iconColor = "green",
}

export const SuccessView = ({ message, onClose, status }) => {
  useEffect(() => {
    const closeTimeout = status === "success" && setTimeout(() => onClose(), 5000);

    return () => {
      if (closeTimeout && status === "success") clearTimeout(closeTimeout);
    };
  }, []);

  return (
    <View>
      <Flex justify="center" align="center" direction="column">
        <Icon name={SuccessViewConstants.icon} color={SuccessViewConstants.iconColor} size={50} />
        <WhiteSpace size="lg" />
        <Text style={styles.bold}>{message}</Text>
        <WhiteSpace size="lg" />
      </Flex>
    </View>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontWeight: "bold",
  },
  white: {
    color: "white",
  },
});
