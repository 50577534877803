import Constants from "expo-constants";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Platform } from "react-native";

import { initializeAuth, getReactNativePersistence, browserLocalPersistence } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";

const firebaseConfig = {
  apiKey: Constants.expoConfig.extra.apiKey,
  authDomain: Constants.expoConfig.extra.authDomain,
  databaseURL: Constants.expoConfig.extra.databaseUrl,
  projectId: Constants.expoConfig.extra.projectId,
  storageBucket: Constants.expoConfig.extra.storageBucket,
  messagingSenderId: Constants.expoConfig.extra.messagingSenderId,
  appId: Constants.expoConfig.extra.appId,
  measurementId: Constants.expoConfig.extra.measurementId,
};

let Firebase = initializeApp(firebaseConfig);

const auth = initializeAuth(Firebase, {
  persistence: Platform.OS === "web" ? browserLocalPersistence : getReactNativePersistence(AsyncStorage),
});

const db = getDatabase(Firebase);
const store = initializeFirestore(Firebase, { localCache: null });

const functions = getFunctions(Firebase);
const storage = getStorage(Firebase);

const convertToArray = (obj) => {
  let newArray = [];
  if (obj) {
    Object.keys(obj).forEach((key) => {
      newArray.push({
        key,
        ...obj[key],
      });
    });
  }
  return newArray;
};

export { auth, db, store, functions, storage, convertToArray };
