import React, { memo } from "react";
import { isEmpty, isNil } from "lodash";
import { Icon } from "@ant-design/react-native";
import { VotesContext } from "../../../providers/SettingsProvider";
import { useVotesContext } from "../../../providers/SettingsProvider";

const withVotesContext = (Component) => {
  const Memoized = memo(Component);

  return (props) => {
    const { allVotes, setAllVotes } = useVotesContext(VotesContext);

    return (
      <Memoized {...props} allVotes={allVotes} setAllVotes={setAllVotes} />
    );
  };
};

export const MemoizedIcon = withVotesContext(
  ({
    allVotes,
    setModalVisible,
    setComment,
    comment,
    category,
    item,
    modalVisible,
  }) => {
    const handleModalOpen = (evidence) => {
      setComment({
        evidence,
        comment: allVotes?.[category]?.[evidence]?.comment ?? "",
      });
      setModalVisible(true);
    };
    const isIconActive =
      ((!isNil(comment.comment) && !isEmpty(comment.comment)) ||
        allVotes?.[category]?.[item]?.comment !== undefined) &&
      !modalVisible;

    return (
      <Icon
        name="message"
        onPress={() => handleModalOpen(item)}
        size="sm"
        color={isIconActive && "black"}
      />
    );
  }
);
